<template>
    <div>
      <span><b style="color: #ff5555;"> <i style="font-size: small;">Please provide the reason(s) for rejecting the applicant</i></b></span>
      <br><br>
      <el-form ref="form"  :inline="false" class="demo-form-inline" :model="form" :rules="rules" @submit.native.prevent>
        <el-form-item label="Remarks" prop="reject_remarks">
          <el-input type="textarea" :rows="3" v-model="form.reject_remarks" placeholder="Remarks" size="small"></el-input>
        </el-form-item>
        <el-form-item style="text-align: right;">
          <el-button type="danger" icon="el-icon-delete" @click="onSubmit">{{ applicant.status == 5 ? 'Re-send reject email' : 'Reject'}}</el-button>
        </el-form-item>
      </el-form>
      <el-dialog
        width="30%"
        :visible.sync="innerVisible"
        :show-close="false"
        :close-on-click-modal="false"
        append-to-body>
        <div v-loading="loading" element-loading-text="Rejecting..." style="margin: 0px auto; padding-bottom: 150px;"></div>
      </el-dialog>
    </div>
</template>

<script>

import { Applicant } from '@/api_services/applicant'

export default {
  name: 'ApplicantReject',
  props: ['applicant'],
  data() {
    return {
       loading: true,
       innerVisible: false,
       form: { reject_remarks: null},
        rules: {
          reject_remarks: [
            { required: true, message: 'Remarks is required', trigger: 'blur' },
          ],
        }
    }
  },
  created: function() {
    this.form.reject_remarks = this.applicant.reject_remarks
  },
  methods: {
    async onSubmit() {
       const valid = await this.$refs.form.validate();
      if (!valid) return;

        this.innerVisible = true
        Applicant.rejectApplication(this.applicant.id, this.form)
        .then(result => {
          if (result) {
            this.$message({
              message: "Applicant has been rejected. An email notification has been sent as well",
              type: 'success',
              duration: 5000
            });
            this.$emit('update:applicant', result.data.data)
          }           
          this.$router.go(0);
        })
        .catch( error => {
          this.loading = false
          console.log(error)
        })
    }
  }
}
</script>