<template>
<el-drawer
    title="VSU Admission"
    :visible.sync="open"
    :with-header="true"
    direction="ltr"
    size="300px"
    class="dark-green-drawer"
    text-color="#031a02"
  >
    <el-menu
        mode="vertical"
      :default-active="activeIndex"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      background-color="#efc62b"
      text-color="#031a02"
      active-text-color="#031a02">

      
      <el-menu-item index="applicants" @click="goToPage('applicants')">
        <i style="color:black" class="el-icon-s-order"></i>
        <span>Applicants</span>
      </el-menu-item>

      <el-menu-item index="f2fapplicants" @click="goToPage('f2fapplicants')" v-if="$store.state.savedUser && $store.state.savedUser.role === 'admin'">
        <i style="color:black" class="el-icon-s-order"></i>
        <span>Face-to-face Applicants</span>
      </el-menu-item>

      <el-menu-item index="schedules" @click="goToPage('schedules')" v-if="$store.state.savedUser && $store.state.savedUser.role === 'admin'">
        <i style="color:black" class="el-icon-date"></i>
        <span>Schedules</span>
      </el-menu-item>

      <el-menu-item index="courses" @click="goToPage('courses')" v-if="$store.state.savedUser && $store.state.savedUser.role === 'admin'">
        <i style="color:black" class="el-icon-office-building"></i>
        <span>Degree programs</span>
      </el-menu-item>

      <el-menu-item index="proctors" @click="goToPage('proctors')" v-if="$store.state.savedUser && $store.state.savedUser.role === 'admin'">
        <i style="color:black" class="el-icon-user" ></i>
        <span>Proctors and Exam Rosters</span>
      </el-menu-item>

      <!-- <el-menu-item index="results" @click="goToPage('results')">
        <i class="el-icon-data-board"></i>
        <span>Results</span>
      </el-menu-item> -->

      <el-submenu index="results" v-if="$store.state.savedUser && $store.state.savedUser.role === 'admin'">
        <template slot="title">
          <i style="color:black" class="el-icon-data-board"></i>
          <span>Results</span>
        </template>
          <el-menu-item index="uploadresults" @click="goToPage('uploadresults')">
            <i style="color:black" class="el-icon-upload"></i>
            <span>Upload Exam Results</span>
          </el-menu-item>
          <el-menu-item index="rankresults" @click="goToPage('rankresults')">
            <i style="color:black" class="el-icon-data-line"></i>
            <span>Rank Results</span>
          </el-menu-item>
          <!-- <el-menu-item index="examresults" @click="goToPage('examresults')">
            <i style="color:black" class="el-icon-data-analysis"></i>
            <span>View Results</span>
          </el-menu-item> -->
      </el-submenu>

      <el-submenu index="settings" v-if="$store.state.savedUser && $store.state.savedUser.role === 'admin'">
        <template slot="title">
          <i style="color:black" class="el-icon-s-tools"></i>
          <span>Settings</span>
        </template>
          <el-menu-item index="users" @click="goToPage('users')">
            <i style="color:black" class="el-icon-user-solid"></i>
            <span>Users</span>
          </el-menu-item>
          <el-menu-item index="configure" @click="goToPage('configure')">
            <i style="color:black" class="el-icon-s-tools"></i>
            <span>Configure</span>
          </el-menu-item>
      </el-submenu>
    </el-menu>
</el-drawer>
</template>

<script>

export default {
  name: 'Sidebar',
  props:['drawer'],
  data() {
    return {
        activeIndex: '',
    }
  },
  computed: {
    open: {
      get(){
          return this.drawer
      },
      set(value){
          this.$emit('update', value)
          return value
      } 
    }
  },
  watch: {
    $route (to, from){
      this.activeIndex = to.name
    }
  },
  created: function() {
      this.activeIndex = this.$route.name
  },
  methods: {
    checkRoute(name) {

    },
      handleOpen(key, keyPath) {
        // console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        // console.log(key, keyPath);
      },
      goToPage(name) {
        const routes = this.$router.options.routes
        const selectedRoute = this.checkRoutes(routes, name)
        if (selectedRoute && this.$router.currentRoute.name !== name) {
          this.$router.push({name: name})
        }
        this.open = false
      },
      checkRoutes(routes, name) {
          const self = this
          let selectedRoute = null
            routes.forEach(function(route){
                if (route.name == name) {
                    selectedRoute = route
                } else {
                    if (route.children) {
                        selectedRoute = self.checkRoutes(route.children, name)
                    }
                }
            })

            return selectedRoute
      }
  }
}
</script>

<style scoped>
  
  .el-submenu__title i {
      color: #000000!important;
  }

  .el-drawer__header {
      color: #000000!important;
  }
  .is-active {
        background-color: #ffffff !important;
  }
</style>