<template>
    <div style="padding: 10px">

      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14" style="color: #f3f3f3;">
          .
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
          <div v-loading="loading">
            <div v-if="(form.status == 1 && form.revert_ctr > 0) || (form.status == 3)" style="text-align: right; margin-bottom: 10px;">
              <el-button v-if="hasNext" type="info" @click="nextApplicant()">Next Applicant <i class="el-icon-caret-right"></i></el-button><br>
              <i style="font-size: small; color: #8b8b8b">{{!hasNext ? "* No more available applicants on queue. Please refresh or go back to applicants page" : "* Click once more if it doesn't redirect to the next applicant."}}</i>
            </div>
            <div v-else style="text-align: right; margin-bottom: 10px;">
              <i style="font-size: small; color: #8b8b8b">* Verify current applicant</i>
            </div>
          </div>
        </el-col>
      </el-row>

        <el-card>
          <el-row :gutter="20">
              <el-col :xs="24" :sm="24" :md="24" :lg="2" :xl="2" style="text-align:center;margin-bottom: 10px">
                <el-badge :value="form.revert_ctr" class="item">
                <el-avatar shape="square" :size="100" fit="cover" center>
                  <img src="@/assets/img_avatar_male.png" width="100" v-if="form.gender == 'M'"/>
                  <img src="@/assets/img_avatar_female.png" width="100" v-if="form.gender == 'F'"/>
                </el-avatar>
                </el-badge>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                <h5 style="text-align:left;margin:0px;margin-left:20px">EXAMINEE CONTROL NUMBER (ECN) : {{ form.control_no || 'N/A' }}</h5>
                              
                
                <el-alert
                  
                    :title="form.final_status"
                    :type="getAlertStatus(form.final_status)"
                    :closable="false" style="margin-top:10px;margin-bottom:10px">
                  </el-alert>
                  <el-alert v-if="(form.status == 2 && form.revert_ctr > 0)"
                    title="REVERTED APPLICANT"
                    type="info"
                    :closable="false" style="margin-top:10px;margin-bottom:10px">
                  </el-alert>
                  <p v-if="form.status == 2 && form.revert_ctr > 0" style="color: #8f8f8f;">REVERT REMARKS: {{ form.remarks }}</p>
                  <p v-if="form.status == 5" style="color: #8f8f8f;">REJECT REMARKS: {{ form.reject_remarks }}</p><br>

                  <el-container style="border: solid 1px #a1a1a1;" v-if="form.prev_scheds.length > 0 && user.role === 'admin'">
                    <el-header style="background-color: #86acbb;color: #333;text-align: center; height: 40px !important; padding: 10px;">Previous Schedules</el-header>
                    <el-main style="padding: 0px">
                      <el-table :data="form.prev_scheds" border size="small">
                        <el-table-column
                          label="Schedule" align="center">
                          <template slot-scope="scope">
                            {{ scope.row.schedule }}
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Score" width="80">
                          <template slot-scope="scope">
                            {{ scope.row.cat_score ? scope.row.cat_score:"---" }}
                          </template>
                        </el-table-column>
                        
                      </el-table>
                      <div style="text-align: right;">
                        <el-button type="info" style="margin: 10px; padding: 5px 10px 5px 10px;" @click="viewDetails(form)">View details</el-button>
                      </div>
                    </el-main>
                  </el-container>
                  
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
                  <el-row>
                    <span style="color: #ff0000;"><b>{{ form.duplicates ? "Warning: Possibility of duplicate application. Please verify carefully!" : ""}}</b></span>
                  </el-row>
                  <el-row>
                    <el-col style="margin-top: 20px; padding: 20px; border: solid 1px #a1a1a1;" :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
                      <el-divider content-position="left"><span style="color: #00ab00"><b>Schedule Details</b></span></el-divider>
                      <el-row :gutter="20" style="padding: 10px">
                          <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
                            <span style="color: #8b8b8b;">Schedule:</span>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
                            <strong> {{ getScheduleText() }}</strong>
                          </el-col>
                      </el-row>

                      <el-row :gutter="20" style="padding: 5px" v-if="form.status === 3 || form.status === 4">
                          <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
                            <span style="color: #8b8b8b;">Moodle Link:</span>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
                            <strong> {{ form.applicant_exam_detail.moodle_link }}</strong>
                          </el-col>
                      </el-row>

                      <el-row :gutter="20" style="padding: 5px" v-if="form.status === 3 || form.status === 4">
                          <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
                            <span style="color: #8b8b8b;">Moodle Username:</span>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
                            <strong> {{ form.applicant_exam_detail.moodle_username }}</strong>
                          </el-col>
                      </el-row>

                      <el-row :gutter="20" style="padding: 5px" v-if="form.status === 3 || form.status === 4">
                          <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
                            <span style="color: #8b8b8b;">Moodle Password:</span>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
                            <strong> {{ form.applicant_exam_detail.moodle_password }}</strong>
                          </el-col>
                      </el-row>

                      
                      <el-row :gutter="20" style="padding: 5px" v-if="form.status === 3 || form.status === 4">
                        <el-divider></el-divider>
                          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <span style="color: #8b8b8b;">Initially verified and scheduled by:</span>
                            <strong> {{ form.verified_by.name }}</strong>
                          </el-col>
                      </el-row>
                        
                    </el-col>
                    <el-col style="padding: 10px;" :xs="24" :sm="24" :md="24" :lg="2" :xl="2">
                    </el-col>
                    <el-col style="padding: 10px;" :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                      <div v-if="form.status == 2 || form.status == 3">
                        <el-button icon="el-icon-alarm-clock" type="success" style="margin-top: 10px; width: 100%;" @click="handleClickExamDetails(form)">{{ !form.applicant_exam_detail ? "Set Exam Schedule" : "Reschedule" }}</el-button><br>
                        <el-button icon="el-icon-d-arrow-left" type="warning" style="margin-top: 10px; width: 100%;" @click="handleClickRevert(form)" :disabled="!form.applicant_exam_detail ? false : true">Revert Application</el-button><br>
                        <el-button icon="el-icon-delete" type="danger" style="margin-top: 10px; width: 100%;" @click="handleClickReject(form)" >Reject Application</el-button>
                      </div>
                      <div style="border: solid 1px #a1a1a1; padding: 5px 10px 5px 10px;" v-if="form.status == 1 && form.revert_ctr > 0">
                        <el-button icon="el-icon-alarm-clock" type="success" style="margin-top: 10px; width: 100%;" @click="handleClickExamDetails(form)">Set Exam Schedule</el-button><br>
                        <el-button icon="el-icon-delete" type="danger" style="margin-top: 10px; width: 100%;" @click="handleClickReject(form)" >Reject Application</el-button>
                        <el-alert
                          title="REVERTED APPLICANT"
                          type="warning"
                          :closable="false" style="margin-top:10px;margin-bottom:10px">
                        </el-alert>
                        <p v-if="form.status == 2 && form.revert_ctr > 0" style="color: #8f8f8f;">REVERT REMARKS: {{ form.remarks }}</p>
                        <p v-if="form.selected_remarks" size="small" style="font-size: 11px; color:red;">Previous Remarks: {{ form.selected_remarks }}</p>
                        <p v-if="form.remarks" size="small" style="font-size: 11px;">Remarks: {{ form.remarks }}</p>
                      </div>

                      <div v-if="form.status === 4 && user.role === 'admin'">
                        <el-button icon="el-icon-alarm-clock" type="success" style="margin-top: 10px; width: 100%;" @click="handleClickExamDetails(form)">{{ !form.applicant_exam_detail ? "Set Exam Schedule" : "Reschedule" }}</el-button><br>
                        <el-container style="margin-top: 10px; border: solid 1px #93c26d;">
                          <el-header style="background-color: #93c26d;color: #333;text-align: center; height: 40px !important; padding: 10px;">Result</el-header>
                          <el-main style="padding: 20px">

                            <el-row :gutter="20" style="padding: 5px">
                              <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                                <span style="color: #8b8b8b;">Avg GPA:</span>
                              </el-col>
                              <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                                <strong> {{ form.applicant_exam_detail.converted_gpa ? form.applicant_exam_detail.converted_gpa : "--" }}</strong>
                              </el-col>
                            </el-row>
                            
                            <el-row :gutter="20" style="padding: 5px">
                              <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                                <span style="color: #8b8b8b;">Moodle Score:</span>
                              </el-col>
                              <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                                <strong> {{ form.applicant_exam_detail.cat_score ? form.applicant_exam_detail.cat_score : "--" }}</strong>
                              </el-col>
                            </el-row>

                            <el-row :gutter="20" style="padding: 5px">
                              <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                                <span style="color: #8b8b8b;">Final Rating:</span>
                              </el-col>
                              <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                                <strong> {{ form.applicant_exam_detail.overall_rating ? form.applicant_exam_detail.overall_rating : "--" }}</strong>
                              </el-col>
                            </el-row>
                            

                            <el-row :gutter="20" style="padding: 5px">
                              <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                                <span style="color: #8b8b8b;">Final Tag:</span><br>
                                
                              </el-col>
                              <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                                <strong> {{ form.applicant_exam_detail.result ? form.applicant_exam_detail.result : "--"}}</strong>
                              </el-col>
                              <span style="color: #8b8b8b; font-size: 12px;">(for pre-enrollment tagging)</span>
                            </el-row>
                            
                          </el-main>
                        </el-container>
                        <el-button icon="el-icon-delete" type="danger" style="margin-top: 10px; width: 100%;" @click="handleClickReject(form)" >Reject Application</el-button>
                      </div>

                    </el-col>
                  </el-row>
              </el-col>
          </el-row>
        </el-card>
        <br>

        <!-- revised applicant view into one page -->
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-card>
              <qaccess-applicant-preview-component :form.sync="form" @saved="saved"></qaccess-applicant-preview-component>
            </el-card>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
              <applicant-attachments-preview-component :applicant.sync="form"></applicant-attachments-preview-component>
          </el-col>
        </el-row>
       
        <el-dialog title="Set Exam Schedule" width="50%" :visible.sync="showExamDetails" v-if="showExamDetails" :destroy-on-close.sync="destroyOnClose">
          <applicant-exam-details-component @button-done="done" :applicant.sync="form"></applicant-exam-details-component>
        </el-dialog>

        <el-dialog title="Revert Application" width="50%" :visible.sync="showRevert" v-if="showRevert" :destroy-on-close.sync="destroyOnClose">
          <applicant-remarks-component :applicant.sync="form"></applicant-remarks-component>
        </el-dialog>

        <el-dialog title="Reject Application" width="50%" :visible.sync="showReject" v-if="showReject" :destroy-on-close.sync="destroyOnClose">
          <applicant-reject-component :applicant.sync="form"></applicant-reject-component>
        </el-dialog>

        <el-dialog title="Previous Schedules" width="50%" :visible.sync="showPrevScheds" v-if="showPrevScheds" :destroy-on-close.sync="destroyOnClose">
          <previous-schedules-component :applicant.sync="form"></previous-schedules-component>
        </el-dialog>
         
        <!-- <el-tabs tab-position="top" style="height: 100%;">
          <el-tab-pane label="Applicant Info">
            <el-card>
              <applicant-preview-component :form.sync="form"></applicant-preview-component>
            </el-card>
          </el-tab-pane>
          <el-tab-pane label="Attachments">
            <el-card>
            <applicant-attachments-preview-component :applicant.sync="form"></applicant-attachments-preview-component>
            </el-card>
          </el-tab-pane>
          <el-tab-pane label="Exam Details" v-if="form.status != 5">
            <el-card>
              <applicant-exam-details-component :applicant.sync="form"></applicant-exam-details-component>
              </el-card>
          </el-tab-pane>
          <el-tab-pane label="Revert" v-if="form.status != 5">
            <el-card>
              <applicant-remarks-component :applicant.sync="form"></applicant-remarks-component>
              </el-card>
          </el-tab-pane>
          <el-tab-pane label="Reject">
            <el-card>
              <applicant-reject-component :applicant.sync="form"></applicant-reject-component>
              </el-card>
          </el-tab-pane>
        </el-tabs> -->
        

    </div>
</template>

<script>
// import ApplicantPreviewComponent from '@/components/applicants/ApplicantPreviewComponent.vue';
import QaccessApplicantPreviewComponent from '@/components/applicants/quickaccess/QaccessApplicantPreviewComponent.vue';
import ApplicantExamDetailsComponent from '@/components/applicants/ApplicantExamDetailsComponent.vue';
import ApplicantAttachmentsPreviewComponent from '@/components/applicants/ApplicantAttachmentsPreviewComponent.vue';
import ApplicantRemarksComponent from '@/components/applicants/ApplicantRemarksComponent.vue';
import ApplicantRejectComponent from '@/components/applicants/ApplicantRejectComponent.vue';
import PreviousSchedulesComponent from '@/components/applicants/PreviousSchedulesComponent.vue';
import * as moment from 'moment';

import { Applicant } from '@/api_services/applicant'

export default {
  // components: { ApplicantPreviewComponent, ApplicantExamDetailsComponent, ApplicantAttachmentsPreviewComponent, ApplicantRemarksComponent, ApplicantRejectComponent },
  components: { QaccessApplicantPreviewComponent,  ApplicantAttachmentsPreviewComponent, ApplicantExamDetailsComponent, ApplicantRemarksComponent, ApplicantRejectComponent, PreviousSchedulesComponent},
  name: 'ApplicantComponent',
  props: ['applicant'],
  data() {
    return {
       loading: false,
       destroyOnClose: true,
       male: '@/assets/img_avatar_male.png',
       female: '@/assets/img_avatar_female.png',
       form: null,
       showExamDetails: false,
       showRevert: false,
       showReject: false,
       showPrevScheds: false,
       applicants: [],
       currentApplicant: null,
       currentIndex: 0,
       meta: {
        page: 1,
        pages: 1,
        total: 211,
        per_page: 10,
       },
       hasNext: false,
       user: this.$store.state.savedUser,
    }
  },
  created: function() {
    this.form = Object.assign({}, this.applicant);
  },
  mounted() {
    this.getApplicants();
  },
  methods: {
    async getApplicants() {
      this.loading = true
      Applicant.get({
        params: { page: this.meta.page, term: '', status: 2, locked: 1},
      })
        .then((result) => {
          this.applicants = result.data.data;
          if(this.applicants.length > 0){
            this.hasNext = true
          } 
          // console.log(this.applicants)
          this.meta = {
            page: result.data.meta.current_page,
            total: result.data.meta.total,
            per_page: result.data.meta.per_page,
            pages: result.data.meta.last_page,
          };
          // this.loading = false;
          this.setCurrentApplicant();
          this.loading = false
          // console.log(result.data.data);
        })
        .catch((error) => {
          // this.loading = false;
          this.applicants = [];
        });
    },
    setCurrentApplicant() {
      this.currentApplicant = this.applicants[this.currentIndex];
      // console.log(this.currentApplicant)
    },
    nextApplicant() {
      this.getApplicants()
      this.currentIndex++;
      if (this.currentIndex < this.applicants.length - 1) {
        this.currentIndex++;
        this.setCurrentApplicant()
      }
      this.$router.push({ name: 'SelectedApplicant', params: { id: this.currentApplicant.id } })
      this.form = this.currentApplicant
      this.$router.go(0);
    },
    getAlertStatus(status) {
      let index = 0
      this.$store.state.final_status.forEach(function(final, i){
        if (final == status) index = i;
      })
      return  this.$store.state.alert_status[index]
    },
    handleClickExamDetails(applicant){
      this.showExamDetails = true;
    },
    handleClickRevert(applicant){
      this.showRevert = true;
    },
    handleClickReject(applicant){
      this.showReject = true;
    },
    viewDetails(applicant){
      this.showPrevScheds = true;
    },
    getScheduleText() {
      if (this.form.applicant_exam_detail && this.form.applicant_exam_detail.schedule) {
        return this.showSchedule(this.form.applicant_exam_detail.schedule)
      }
      return 'N/A'
   },
   formatTime(time) {
      const [hours, minutes] = time.split(':');
      let hourNum = parseInt(hours, 10);
      const period = hourNum >= 12 ? 'PM' : 'AM';
      hourNum = hourNum % 12 || 12;
      const formattedTime = `${hourNum}:${minutes} ${period}`;

      return formattedTime;
    },
   showSchedule(schedule) {
      let start = schedule.time_start.split(":")[0]+":"+schedule.time_start.split(":")[1];
      let end = schedule.time_end.split(":")[0]+':'+schedule.time_end.split(":")[1];
      
      return this.getDate(schedule.date)+" | "+this.formatTime(start)+' - '+this.formatTime(end);
    },
    getDate(date){
      date = moment(date).format('MMMM D, YYYY')
      return date
    },
    done(){
      this.showExamDetails = false
      this.$router.go(0);
    },
    saved(applicant) {
      this.$emit("saved", applicant);
    },
  }
}
</script>