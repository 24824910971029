import api from './api'

const API = '/api/examrosters'

export const ExamRoster = {
    get: function (params) {
        return api.get(API, params)
    },
    create: function (params) {
        return api.create(API, params)
    },
    show: function (id, params) {
        return api.get(API + '/' + id, params)
    },
    update: function (id, params) {
        return api.update(API + '/' + id, params)
    },
    getBy: function (id, params) {
        return api.get(API + '/id/' + id, params)
    },
    delete: function (id, params) {
        return api.delete(API + '/' + id, params)
    },
    createRoster: function(params) {
        return api.create(API + '/store-roster', params)
    },
    getScheduledProctors: function(params) {
        return api.get(API + '/get-scheduled-proctors', params)
    },
    generatepdf: function (params) {
        return api.create(API + '/generate-pdf', params)
    },
}
