<template>
    <div style="padding: 20px;" >
      <el-row :gutter="20" v-loading="loading">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-divider content-position="left"><span style="color: #00ab00"><b>Moodle Information</b></span></el-divider>
                  <el-form ref="form" :model="exam_detail" @submit="onSubmit()"  :rules="rules">
              <el-row :gutter="20">
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                  <el-form-item label="Moodle Username" prop="moodle_username">
                    <el-input v-model="exam_detail.moodle_username" placeholder="Moodle Username" size="large" :readonly="!!exam_detail.id" :disabled="user.role === 'staff'"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                  <el-form-item label="Moodle Password" prop="moodle_password">
                    <el-input v-model="exam_detail.moodle_password" placeholder="Moodle Password" size="large" :readonly="!!exam_detail.id" :disabled="user.role === 'staff'">

                      <el-popover
                      placement="top-start"
                      width="200"
                      trigger="hover"
                      content="Randomize password"
                      slot="append">
                        <el-button slot="reference" type="success" icon="el-icon-refresh" @click="randomizePassword()" circle></el-button>
                      </el-popover>
                    </el-input>
                   
                  </el-form-item>
                   
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                  <el-form-item label="Moodle Link" prop="moodle_link">
                    <el-input v-model="exam_detail.moodle_link" placeholder="Moodle Link" size="large" :readonly="!!exam_detail.id" :disabled="user.role === 'staff'"></el-input>
                  </el-form-item>
                </el-col>
                 <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                  <el-form-item label="Schedule" prop="schedule_id">
                    <el-select v-model="exam_detail.schedule_id" placeholder="Schedule" size="large" style="width: 100%;" :disabled="user.role === 'staff'">
                      <el-option label="Select schedule" :value="null"></el-option>
                      <el-option :label="showSchedule(schedule)" :value="schedule.id" v-for="schedule in schedules" :key="schedule.id"></el-option>
                    </el-select>

                  </el-form-item>
                  
                </el-col>
              </el-row>

              <el-row :gutter="20" v-if="exam_detail.id">
                <el-divider content-position="left"><span style="color: #00ab00"><b>Reschedule Remarks</b></span></el-divider>
                <!-- <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                  <el-form-item label="Exam Status" prop="exam_status">
                    <el-select style="width: 100%;" v-model="exam_detail.exam_status" placeholder="Select Exam Status">
                      <el-option
                      v-for="item in $store.state.exam_status_options"
                      :key="item.id"
                      :label="item.value"
                      :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col> -->
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <el-form-item label="Exam remarks (optional)" prop="exam_remarks">
                    <el-input type="textarea" :rows="3" v-model="exam_detail.exam_remarks" placeholder="Remarks" size="large"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

            <el-form-item style="margin-top: 20px; text-align: right">
              <el-button icon="el-icon-alarm-clock" type="success" @click="onSubmit">{{ !exam_detail.id ? "Set Schedule" : "Reschedule" }}</el-button>
          </el-form-item>
          </el-form>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">
          .
        </el-col>

        <!-- <div v-if="exam_detail.overall_rating !== null">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-divider content-position="left"><span style="color: #00ab00"><b>Result Information</b></span></el-divider>
            <el-form ref="resultForm" :model="exam_detail" @submit="onSubmitResult()"  :rules="result_rules">
              <el-row :gutter="20">
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    <el-form-item label="Result" prop="result">
                        <el-select v-model="exam_detail.result" placeholder="Result" size="large" style="width: 100%;" :disabled="user.role === 'staff'">
                          <el-option :label="result.value" :value="result.id" v-for="result in $store.state.results" :key="result.id"></el-option>
                        </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    <el-form-item label="Cat Score" prop="cat_score">
                      <el-input readonly="readonly" v-model="exam_detail.cat_score" placeholder="Cat Score" size="large" :disabled="user.role === 'staff'"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    <el-form-item label="Over all rating" prop="overall_rating">
                      <el-input readonly="readonly" v-model="exam_detail.overall_rating" placeholder="Over all rating" size="large" :disabled="user.role === 'staff'"></el-input>
                    </el-form-item>
                  </el-col>
              </el-row>
                <el-form-item style="margin-top: 20px; text-align: right">
                    <el-button :disabled="disabled" type="primary" @click="onSubmitResult">Save</el-button>
                </el-form-item>
            </el-form>
          </el-col>
        </div>   -->

      </el-row>
      <el-dialog
        width="30%"
        :visible.sync="innerVisible"
        :show-close="false"
        :close-on-click-modal="false"
        append-to-body>
        <div v-loading="loadingDia" element-loading-text="Assigning Exam Schedule..." style="margin: 0px auto; padding-bottom: 150px;"></div>
      </el-dialog>

    </div>
</template>

<script>

import { Applicant } from '@/api_services/applicant'
import { Schedule } from '@/api_services/schedule'
import * as moment from 'moment';


export default {
  name: 'ApplicantExamDetail',
  props: ['applicant'],
   data() {
    return {
      schedules: [],
      user: this.$store.state.savedUser,
      loading: false,
      innerVisible: false,
      loadingDia: true,
      exam_detail: {
        moodle_link: null,
        moodle_username: null,
        moodle_password: null,
        cat_score: null,
        result: null,
        overall_rating: null,
        schedule_id: null,
        applicant_id: this.applicant.id,
        exam_remarks: null,
      },
      rules: {
        moodle_link: [
          { required: true, message: "Moodle link is required", trigger: "blur"}
        ],
        moodle_username: [
          { required: true, message: "Moodle username is required", trigger: "blur"}
        ],
        moodle_password: [
          { required: true, message: "Moodle password is required", trigger: "blur"}
        ],
        schedule_id: [
          { required: true, message: "Schedule is required", trigger: "blur"}
        ],
      },
      result_rules: {
        cat_score: [
          { required: true, message: "CAT Score is required", trigger: "blur"}
        ],
        result: [
          { required: true, message: "Result is required", trigger: "blur"}
        ],
        overall_rating: [
          { required: true, message: "Over all rating is required", trigger: "blur"}
        ]
      },
      disabled: true
    }
  },
  created: function() {
    this.loading = true
    if (this.applicant.applicant_exam_detail) {
      this.exam_detail = this.applicant.applicant_exam_detail
    }

    if (!this.exam_detail.moodle_password) {
      this.randomizePassword();
    }

    if (!this.exam_detail.moodle_username) {
      this.generateUsername();
    }
    
    if (!this.exam_detail.moodle_link) {
      this.exam_detail.moodle_link = 'https://cat-exam1.vsu.edu.ph/';
    }
    this.getSchedules()
  },
  methods: {
    randomizePassword() {
      const alpha = "abcdefghijklmnopqrstuvwxyz";
      const numbers = "0123456789";
      const symbols = "!@#$_+";

      const characters = alpha + numbers;

      let password = '';
      for (let i = 0; i < 5; i++) {
        password += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }

      password = alpha.charAt(Math.random() * alpha.length).toUpperCase() + password;
      password += numbers.charAt(Math.floor(Math.random() * numbers.length));
      password += symbols.charAt(Math.floor(Math.random() * symbols.length));

      this.exam_detail.moodle_password = password;
    },
    generateUsername() {
      const first = this.applicant.firstname.charAt(0).toLowerCase();
      // const middle = this.applicant.middlename.charAt(0).toLowerCase();
      let middle = "";

      if (this.applicant.middlename) {
        middle = this.applicant.middlename.charAt(0).toLowerCase();
      }

      this.exam_detail.moodle_username = first + middle + this.applicant.lastname.replace(/\s+/g, '').replace(/ñ/g,"n").toLowerCase();
    },
    showSchedule(schedule) {
      let day = moment(schedule.date).format('ddd');
      let start = schedule.time_start.split(":")[0]+":"+schedule.time_start.split(":")[1];
      let end = schedule.time_end.split(":")[0]+':'+schedule.time_end.split(":")[1];
      start = this.formatTime(start)
      end = this.formatTime(end)
      let available = Math.max(schedule.slots - schedule.entries, 0)
      return moment(schedule.date).format('MMMM D, YYYY')+" ("+day+") | "+start+' - '+end+" ("+available+" open)"
    },
    formatTime(time) {
      const [hours, minutes] = time.split(':');
      let hourNum = parseInt(hours, 10);
      const period = hourNum >= 12 ? 'PM' : 'AM';
      hourNum = hourNum % 12 || 12;
      const formattedTime = `${hourNum}:${minutes} ${period}`;

      return formattedTime;
    },
    getSchedules() {
      Schedule.get({params: {available: true, from: new Date().toISOString().split('T')[0], per_page: 100}}).then(result => {

        // filtering for religions with saturday church
        if(this.applicant.religion.id != 25 || this.applicant.religion.id != 86){
          this.schedules = result.data.data
        } else {
          this.schedules = result.data.data.filter(item => {
            const currentDate = typeof item.date === 'string' ? new Date(item.date) : item.date;
            return currentDate.getDay() !== 6;
          });
        }
          this.loading = false
          this.addApplicantSchedule()
        })
        .catch( error => {
          console.log(error)
        })
    },
    addApplicantSchedule() {
      let found = false;
      let self = this;
      if (!this.exam_detail.schedule) return
      this.schedules.forEach(function(sched){
          if (sched.id === self.exam_detail.schedule.id) found = true
      })

      if (!found) this.schedules.push(this.exam_detail.schedule)
    },
    async onSubmit() {
        const valid = await this.$refs.form.validate();
        if (!valid) return;

        this.$confirm('Confirm Schedule for <strong>' + this.applicant.firstname + ' ' + this.applicant.lastname + '</strong>?', 'Confirmation', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'info',
          dangerouslyUseHTMLString: true,
          center: true
        }).then(() => {
          this.innerVisible = true
          if (this.exam_detail.id) {
            Applicant.updateSchedule(this.exam_detail.applicant_id, this.exam_detail)
            .then(result => {
              if (result) {
                this.$message({
                  message: "Schedule updated succesfully",
                  type: 'success',
                  duration: 3000
                });
              this.getSchedules()
              // this.$router.go(0);
              // this.applicant = result.data.data
              this.innerVisible = false
              this.loadingDia = false
              this.$emit('button-done');

                // this.applicant.control_no = result.data.data.control_no
                // this.applicant.final_status = result.data.data.final_status
                // // this.applicant.control_no = result.data.data.control_no
              }           
            })
            .catch( error => {
              this.loading = false
              this.innerVisible = false
              console.log(error)
            })
            return;
          }

          Applicant.completeApplication(this.exam_detail.applicant_id, this.exam_detail)
          .then(result => {
            if (result) {
              this.$router.go(0)
              this.$message({
                message: "Exam details has been saved successfully",
                type: 'success',
                duration: 3000
              });
              this.getSchedules()
              this.applicant.status = result.data.data.status
              this.applicant.control_no = result.data.data.control_no
              this.applicant.final_status = result.data.data.final_status
              // this.applicant.control_no = result.data.data.control_no
              
            }       
               
          })
          .catch( error => {
            this.loading = false
            this.innerVisible = false
            console.log(error)
          })
        }).catch(() => {

        });

        

    },
    async onSubmitResult() {
        const valid = await this.$refs.resultForm.validate();
        if (!valid) return;

        Applicant.updateExamResult(this.exam_detail.applicant_id, this.exam_detail)
        .then(result => {
          if (result) {
            this.$message({
              message: "Exam result has been saved successfully",
              type: 'success',
              duration: 3000
            });
            this.applicant.status = result.data.data.status
            this.applicant.control_no = result.data.data.control_no
            this.applicant.final_status = result.data.data.final_status
          }           
        })
        .catch( error => {
          this.loading = false
          console.log(error)
        })
    }
  }
}
</script>