<template>
    <div>          
        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <el-divider content-position="left"><span style="color: #00ab00"><b>Basic Information</b></span></el-divider>
                <ul style="list-style-type: none;padding-left: 5px;">
                <li style="padding: 5px;"><span>First Name : </span><b style="color: #ff0000">{{ form.firstname}}</b></li>
                <li style="padding: 5px;"><span>Middle Name : </span><b style="color: #ff0000">{{ form.middlename}}</b></li>
                <li style="padding: 5px;"><span>Last Name : </span><b style="color: #ff0000">{{ form.lastname}}</b></li>
                <li style="padding: 5px;"><span>Ext : </span><b style="color: #ff0000">{{ form.extension}}</b></li>
                <li style="padding: 5px;"><span>Email : </span><b>{{ form.email}}</b> 
                    <el-button v-if="user.role === 'admin' && form.status >= 2" class="el-icon-edit" size="small" circle @click="handleClickEmail(form)"/>
                </li>
                <li style="padding: 5px;"><span>Sex : </span><b>{{ showGender(form) }}</b> <i v-if="(form.is_sex_pnts == 1)" style="font-size: 11px; color: red;">*Prefers not to say</i></li>
                <li style="padding: 5px;"><span>Contact No : </span><b>{{ form.contact_no}}</b></li>
                <li style="padding: 5px;"><span>Birth place : </span><b>{{ form.birthplace}}</b></li>
                <li style="padding: 5px;"><span>Birth date : </span><b style="color: #ff0000">{{ getDate(form.birth_date)}}</b></li>
                <li style="padding: 5px;"><span>Citizenship : </span><b>{{ getCitizenship(form)}}</b></li>
                <li style="padding: 5px;"><span>Civil status : </span><b>{{ getStatus(form)}}</b></li>
                <li style="padding: 5px;"><span>Name of Spouse : </span><b>{{ form.spouse_name }}</b></li>
                <li style="padding: 5px;"><span>Number of children : </span><b>{{ form.no_of_children }}</b></li>
                <li style="padding: 5px;"><span>Religion : </span><b>{{ form.religion.name }}</b></li>
                
                </ul>
            </el-col>
            

            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <el-divider content-position="left"><span style="color: #00ab00"><b>Other Information</b></span></el-divider>

                <ul style="list-style-type: none;padding-left: 5px;">
                
                <li style="padding: 5px;">Degree program : <b>{{ form.course ? form.course.code + ' '+ form.course.description + ' ' + (form.course.major || '')  : '' }}</b></li>
                <li style="padding: 5px;">Preferred Campus : <b>{{ form.campus }}</b>
                    <el-button v-if="user.role === 'admin' && form.status >= 2" class="el-icon-edit" size="small" circle @click="handleCampusCourse(form)"/>
                </li>
                <div style="border: solid #d8d8d8 1px">
                    <li style="padding: 5px;">Grade 9 GPA : <b style="color: #ff0000"><u>{{ form.grade_9_gpa }}</u></b></li>
                    <li style="padding: 5px;">Grade 10 GPA : <b style="color: #ff0000"><u>{{ form.grade_10_gpa }}</u></b></li>
                    <li style="padding: 5px;">Grade 11 GPA : <b style="color: #ff0000"><u>{{ form.grade_11_gpa }}</u></b></li>
                    <li style="padding: 5px;">Latest Education Attainment : </li>
                    <li style="padding: 5px;margin-left: 15px;">
                        <b style="color: #ff0000">{{ getHsTypeName(form.hs_type) }} <br>GPA: <u>{{ form.hs_type_gpa }}</u> </b>
                        <el-button v-if="user.role === 'admin' && form.status >= 2" class="el-icon-edit" size="small" circle @click="handleClickGPA(form)"/>
                    </li>
                </div>
                

                <div v-if="form.strand !== null">
                    <li style="padding: 5px;">SHS Strand : </li>
                    <li style="padding: 5px;margin-left: 15px;">
                        <b>{{ form.strand.strand_code }} - {{ form.strand.strand_description }}</b>
                    </li>
                </div>

                <div v-if="form.gender === 'F'">
                    <li style="padding: 5px;">First female in the family to attend college: </li>
                    <li style="padding: 5px;margin-left: 15px;">
                        <b>{{ form.is_first_female === 'Yes' ? 'Yes': 'No'}}</b>
                    </li>
                </div>

                <div v-if="form.gender === 'M'">
                    <li style="padding: 5px;">First male in the family to attend college: </li>
                    <li style="padding: 5px;margin-left: 15px;">
                        <b>{{ form.is_first_male === 'Yes' ? 'Yes': 'No'}}</b>
                    </li>
                </div>
                
                <li style="padding: 5px;">Social Categories : </li>
                <li style="padding: 5px;margin-left: 15px;" v-for="social in getSocialCategoriesList()" :key="social"><b style="color: #ff0000">- {{ social}}</b></li>
                <li style="padding: 5px;">Any allergies or health problem :</li>
                <li style="padding: 5px;margin-left: 15px;">
                    <b>{{ form.pwd_handicap || 'None' }}</b>
                </li>
                </ul>
            </el-col>
        </el-row>  

        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-divider content-position="left"><span style="color: #00ab00"><b>Home Address</b></span></el-divider>

                <ul style="list-style-type: none;padding-left: 5px;">
                <li style="padding: 5px;">Street No / Bldg : <b>{{ form.address ? form.address.address1 : ''}}</b></li>
                <li style="padding: 5px;">Brgy : <b>{{ form.address ? form.address.address2 : ''}}</b></li>
                <li style="padding: 5px;">Town/City :  <b>{{ form.address.town ? form.address.town.name : ''}}</b> </li>
                <li style="padding: 5px;">Province :  <b>{{ form.address.town ? form.address.town.province.name : ''}}</b> </li>
                </ul>
                
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-divider content-position="left"><span style="color: #00ab00"><b>Guardians</b></span></el-divider>
                <ul style="list-style-type: none;padding-left: 5px;">
                    <li style="padding: 5px;">
                    <el-table size="small" border
                        :data="form.guardians"
                        style="width: 100%">
                        <el-table-column
                            prop="fullname"
                            label="Fullname"
                            >
                        </el-table-column>
                        <el-table-column
                        prop="occupation"
                        label="Occupation"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="monthly_income"
                        label="Monthly income"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="relation"
                        label="Relation"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="contact_no"
                        label="Contact No"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="address1"
                        label="Street/Brgy"
                        >
                        </el-table-column>
                        <el-table-column
                        label="Town">
                        <template slot-scope="scope">
                            {{ scope.row.town ? scope.row.town.name+', '+scope.row.town.province.name : ''}}
                        </template>
                        </el-table-column>
                        
                    </el-table>
                    </li>
                </ul>
                <br>
                <el-divider content-position="left"><span style="color: #00ab00"><b>Attended Schools</b></span></el-divider>
                
                <ul style="list-style-type: none;padding-left: 5px;margin-bottom: 20px;">
                    <li style="padding: 5px;">
                    <el-table
                        size="small" border
                        :data="form.schools_attended"
                        style="width: 100%">
                        <el-table-column
                            prop="date_attended"
                            label="Year Graduated"
                            >
                            <template slot-scope="scope">
                            {{  scope.row.date_attended }}
                        </template>

                        </el-table-column>
                        <el-table-column
                        label="School"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.school ? scope.row.school.name : ''}}
                        </template>
                        </el-table-column>
                        <el-table-column
                        label="Type"
                        width="100">
                        <template slot-scope="scope">
                            {{ scope.row.school ? scope.row.school.type : ''}}
                        </template>
                        </el-table-column>
                        <el-table-column
                        label="Level"
                        width="100">
                        <template slot-scope="scope">
                            {{ scope.row.school ? getSchoolLevel(scope.row.school.level) : ''}}
                        </template>
                        </el-table-column>
                        <el-table-column
                        label="Type"
                        >
                        <template slot-scope="scope">
                            {{ scope.row.school && scope.row.school.town ? scope.row.school.town.name+', '+scope.row.school.town.province.name : ''}}
                        </template>
                        </el-table-column>
                    
                    </el-table>
                    </li>
                </ul>

                    <!-- <div>
                        <el-divider content-position="left" >Attachments</el-divider>
                        <ul style="list-style-type: none;padding-left: 5px;">
                            <li >
                            
                            <el-link style="margin: 20px;" v-for="attachment in getAttachments()" :key="attachment.id" type="primary" :href="attachment.full_path" target="_blank">
                                <i class="el-icon-paperclip"></i> {{ getFormType(attachment.type) }} 
                                </el-link>
                            </li>
                        </ul>
                    </div> -->
            </el-col>
        </el-row>
        
        <el-dialog title="Update Campus and Course" width="30%" v:on:close-dialog="closeDialog" :visible.sync="showDialogcourse" v-if="showDialogcourse" :destroy-on-close.sync="destroyOnClose">
            <update-course-component :selected.sync="form"  @saved="saved"></update-course-component>
        </el-dialog>

        <el-dialog title="Update GPA" width="30%" v:on:close-dialog="closeDialog" :visible.sync="showDialogGPA" v-if="showDialogGPA" :destroy-on-close.sync="destroyOnClose">
            <update-gpa-component :selected.sync="form" @saved="saved"></update-gpa-component>
        </el-dialog>

        <el-dialog title="Update Email" width="30%" v:on:close-dialog="closeDialog" :visible.sync="showDialogEmail" v-if="showDialogEmail" :destroy-on-close.sync="destroyOnClose">
            <update-email-component :selected.sync="form" @saved="saved"></update-email-component>
        </el-dialog>
    

    </div>
</template>

<script>

import * as moment from 'moment';
import UpdateEmailComponent from '@/components/applicants/UpdateEmailComponent.vue';
import UpdateCourseComponent from '@/components/applicants/UpdateCourseComponent.vue';
import UpdateGpaComponent from '@/components/applicants/UpdateGpaComponent.vue';

export default {
  components: {
    UpdateEmailComponent, UpdateGpaComponent, UpdateCourseComponent
  },
  name: 'ApplicantPreview',
  props: ['form', 'show_attachment'],
  data() {
    return {
      destroyOnClose: true,
      selected: null,
      showDialogEmail: false,
      showDialogcourse: false,
      showDialogGPA: false,
      user: this.$store.state.savedUser,
    }
  },
  created() {
    console.log(this.form);
  },
  methods: {
    saved(applicant) {
      this.showDialogcourse = false,
      this.showDialogGPA = false;
      this.showDialogEmail = false;
      this.$emit("saved", applicant);
    },
    handleCampusCourse(applicant){
      this.showDialogcourse = true;
    },
    handleClickEmail(applicant){
      this.showDialogEmail = true;
    },
    handleClickGPA(applicant){
      this.showDialogGPA = true;
    },
    getAttachments() {
      const attachmentTypes = [];
      const attachmentMap = this.$store.state.attachment_types_map.find(attachmentType => attachmentType.hs_type === this.form.hs_type);

      attachmentMap.form_types.forEach(type => {
        attachmentTypes.push(type);
      });

      this.form.social_categories.forEach((cat, idx) => {
        if (cat === true) {
          const socialMapIndex = this.$store.state.social_categories_and_attachment_types[idx];
          attachmentTypes.push(socialMapIndex);
        }
      });

      return this.form.attachments.filter(attachment => {
        return attachmentTypes.includes(attachment.type)
      });
    },

    showGender(item) {
      let string = ""
      this.$store.state.genders.forEach(function(gender){
        if (gender.id == item.gender ) {
          string = gender.value
        }
      })

      return string
    },
    getHsTypeName(hs_type) {
        let string = ""
        this.$store.state.hs_types.forEach(function(type){
          if (type.id == hs_type ) {
            string = type.value
          }
        })  

      return string
    },
    getDate(date) {
        return moment(date).format('MMMM D, YYYY')
    },
    getSchoolLevel(level) {
      let res = ""
      this.$store.state.school_levels.forEach(function(l){
        if (l.id == level) res = l.value
      })
      return res
    },
    getFormType(type) {
      let res = ""
      this.$store.state.form_types.forEach(function(f){
        if (f.id == type) {
          res = f.value
          if (f.note) {
            res += "( "+f.note+" )"
          }
        }
      })
      return res
    },
     getCitizenship(item) {
      let res = ""
      this.$store.state.citizenships.forEach(function(c){
        if (c.id == item.citizenship) res = c.value
      })
      return res
    },
    getStatus(item) {
      let res = ""
       this.$store.state.statuses.forEach(function(s){
        if (s.id == item.civil_status) res = s.value
      })
      return res
    },
    getSocialCategoriesList() {
      let list = [];
      let self = this
      if (!this.form.social_categories) { return []; }
      this.form.social_categories.forEach(function(social,index){
        if (social) list.push(self.$store.state.social_category_names[index])
      })

      return list;
    }
  }
}
</script>