<template>
    <div>
      <el-form
      :model="form">
        <el-form-item label="Name:">
          <span><b>{{ form.full_name }}</b></span>
        </el-form-item>
        <el-form-item label="Campus:">
          <span><b>{{ form.campus }}</b></span>
        </el-form-item>
        <el-form-item label="Couse:">
          <span><b>{{ form.course.code }} {{ form.course.major ? " - Major in "+form.course.major : ""}}</b></span>
        </el-form-item>
        <el-form-item label="Initial Tag: ">
          <el-select v-model="form.temp_tag" placeholder="Temporary Tag" size="large" style="width: 100%;">
            <el-option :label="tag" :value="tag" v-for="tag in $store.state.tag" :key="tag"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
        <el-button type="primary" @click="update" style="width: 100%;">Update</el-button>
        <!-- <el-button @click="resetForm">Reset</el-button> -->
        </el-form-item>
      </el-form>
        
    </div>
    
  </template>

  <script>
  import { Ranking } from "@/api_services/ranking";

  export default {
    name: 'EditTag',
    props: ['selected'],
    data() {
      return {
        form: this.selected,
      }
    },
    created() {

    },
    methods: {
      update(){
        this.$confirm('Are you sure you want to update the Initial Tag for <strong>' + this.form.full_name +' </strong>?', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning',
            dangerouslyUseHTMLString: true,
        }).then(() => {
            this.loading = true;
            Ranking.updateTag({ 
              applicant_id: this.form.applicant_id, temp_tag: this.form.temp_tag
            })
            .then((result) => {
                this.loading = false;
                this.form = result.data.data
                this.$emit("saved");
            })
            .catch((error) => {
                this.loading = false;
                console.log(error);
            });
        }).catch(() => {
            
        });
      }
    }
  };
</script>