<template>
    <div style="padding: 20px" v-loading.fullscreen.lock="loading">
        <div v-if="!loading">
          <div v-if="applicant">
              <applicant-component :applicant.sync="applicant" @saved="saved"></applicant-component>
          </div>
          <page-not-found :message="'Applicant not found'" v-else></page-not-found>
        </div>
    </div>
</template>

<script>

import { Applicant } from '@/api_services/applicant'
import ApplicantComponent from '@/components/applicants/ApplicantComponent.vue'

export default {
  components: { ApplicantComponent },
  name: 'SelectedApplicantPage',
  data() {
    return {
      loading: false,
      applicant: null,
      canEdit: false
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.canEdit) { 
      next(); 
      return;
    }
  
    Applicant.unLock(this.$router.currentRoute.params.id).then(response => {
      next();
    });
  },
  created: function() {
     this.getApplicant(this.$router.currentRoute.params.id)
  },
  methods: {
    getApplicant(id) {
      this.loading = true
      
      Applicant.show(id, {params: {with_lock: true}}).then(result => {
        this.loading = false
        if (
          result.data.data.status <= 3 && 
          result.data.data.locked && 
          result.data.data.locked_by.id !== this.$store.state.savedUser.id
        ) {
          this.$router.push({name: 'applicants'});

          setTimeout(() => {
            alert('This applicant is being reviewed by another user: ' + result.data.data.locked_by.name)
          }, 500);
          
          return;
        }

        this.applicant = result.data.data;

        if (this.applicant.status !== 3) {
          Applicant.lock(id).then(response => {
            this.applicant = Object.assign({}, this.applicant, response.data);
          });
        }

        this.canEdit = true;
      })
      .catch( error => {
        this.loading = false
        
        console.log(error)
      });
    },
    saved(applicant) {
      // this.applicant = applicant
      this.applicant = Object.assign({}, applicant);
      // console.log(this.applicant)
    },
  }
}
</script>