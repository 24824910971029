<template>
  <div>
    
          <!-- imports face to face appicants ot DB -->
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
      
          <el-row hidden :gutter="20" style="padding-bottom:25px;">
            <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
              <div class="demo-collapse">
                <el-collapse>
                  <el-collapse-item title="Import" name="1">
                    <el-card >
                      <el-form ref="form" :model="results" @submit="onSubmit()">
                          <el-alert :closable="false" title="Before uploading please rename the column headers to:" type="warning" 
                           description="`control_no`, `firstname`, `middlename`, `lastname`, `extension`, `contact_no`, 
                          `birth_date`, `gender`, `religion_id`, `citizenship`, `civil_status`, 
                          `status`, `campus`, `course_id`, `educational_category`, `gpa`, `social_category`, 
                          `grade_9_gpa`, `grade_10_gpa`, `grade_11_gpa`, `hs_type`, `hs_type_gpa`, `social_categories`, 
                          `pwd_handicap`, `health_problems`, `strand_id`, `is_first_female`, `is_sex_pnts`"/>
                          <el-form-item>
                              <el-input type="file" v-model="results.results_file"></el-input>
                              <el-button type="submit" 
                              @click="uploadResults"
                              size="meduim"
                              :loading="loading_upload"
                              icon="el-icon-upload"
                              name="upload">Upload and Import Applicants</el-button>

                          </el-form-item>
                      </el-form>
                    </el-card>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </el-col>
          </el-row>


          <el-form :inline="true" :model="searchForm" class="demo-form-inline">

            <el-form-item label="Name">
              <el-input
                v-model="searchForm.name"
                placeholder="Name"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="onSearch"
                size="small"
                :loading="loading"
                icon="el-icon-search"
                >Search</el-button>
            </el-form-item>
            <el-form-item>
              <el-tag class="el-tag-dark-green">Total: {{ meta.total }}</el-tag>
            </el-form-item>
            <!-- <el-form-item>
              <el-dropdown split-button type="primary" size="small" @command="exportApplicants" v-if="$store.state.savedUser.role === 'admin'">
                Export
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="campus.id" :key="campus.id" v-for="campus in $store.state.campuses">{{ campus.id }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button type="primary" size="small" @click="exportApplicants($store.state.savedUser.campus)" v-if="$store.state.savedUser.role !== 'admin'">Export Applicants</el-button>
            </el-form-item> -->
            <el-form-item class="right">
              
              <el-button size="small" type="success" @click="addApplicant"><i class="el-icon-plus"></i> ADD APPLICANT</el-button>
            </el-form-item>
          </el-form>

          <el-table :data="applicants"
          v-loading="loading"
          element-loading-text="Loading..."
          element-loading-spinner="el-icon-loading" size="mini" max-height="600" 
          style="width: 100%; cursor: pointer;"
          highlight-current-row
          @current-change="handleCurrentChange">
            <el-table-column prop="id" label="ID" width="70"></el-table-column>
            <el-table-column prop="firstname" label="First Name"></el-table-column>
            <el-table-column prop="middlename" label="Middle Name"></el-table-column>
            <el-table-column prop="lastname" label="Last Name"></el-table-column>
            <!-- <el-table-column prop="email" label="Email" width="200"></el-table-column>
            <el-table-column prop="contact_no" label="Contact No"></el-table-column> -->
        
            <el-table-column prop="gender" label="Sex">
              <template slot-scope="scope">
                  {{ showGender(scope.row)}}
              </template>
            </el-table-column>
            <el-table-column prop="campus" label="Campus"></el-table-column>
      
            <el-table-column prop="course_id" label="Degree program" width="130">
              <template slot-scope="scope">
                <el-popover
                  v-if="scope.row.course"
                  placement="top-start"
                  :title="scope.row.course.code"
                  width="200"
                  trigger="hover"
                  :content="scope.row.course.description">
                  <el-link type="info" slot="reference">{{ scope.row.course.code }}</el-link>
                </el-popover>
                  
              </template>
            </el-table-column>

            <!-- <el-table-column fixed="right" label="Action" width="120">
              <template slot-scope="scope">
        
                <el-button
                  @click="showReview(scope.row)"
                  type="text"
                  size="small"
                  style="margin-right: 5px"
                  >View</el-button
                > 

              </template>
            </el-table-column> -->

          </el-table>
          <el-pagination
          class="margin-top-20"
          background
          layout="prev, pager, next"
          :total="meta.total"
          :page-size="15"
          @current-change="pageChanged"
          :current-page="meta.page"
            >
        </el-pagination>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <el-card class="box-card" shadow="never">
          <div style="text-align: center;">
            <h2 v-if="!showDetails">Select an Applicant</h2> 
          </div>
          <f2f-applicant-details-component v-if="showDetails" :selected="selected" :key="componentKey" ></f2f-applicant-details-component>
        </el-card>
      </el-col>
    </el-row>    


    
    <el-dialog title="Add applicant" width="95%" :visible.sync="showDialog" :before-close="handleClose" :destroy-on-close="true">
      <add-applicant-component @button-done="done"></add-applicant-component>
    </el-dialog>
  </div>
</template>

<style>
.el-collapse-item__header {
    padding-left:10px;
}
</style>

<script>
// eslint-disable-next-line no-unused-vars



import { Applicant } from "@/api_services/applicant";
import AddApplicantComponent from './AddApplicantComponent.vue';
import F2fApplicantDetailsComponent from './F2fApplicantDetailsComponent.vue';
// import ApplicantAttachmentsPreviewComponent from './ApplicantAttachmentsPreviewComponent.vue';
// import ApplicantRemarksComponent from './ApplicantRemarksComponent.vue';
// import ApplicantRejectComponent from './ApplicantRejectComponent.vue';

export default {
  components: {
    AddApplicantComponent, F2fApplicantDetailsComponent
  },
  name: "F2fapplicants",
  data() {
    return {
      loading: false,
      loading_upload: false,
      searchForm: {},
      drawer: false,
      review: false,
      showDialog: false,
      applicant: {
        id: 1,
        firstname: "Micco",
        middlename: "Augusto",
        lastname: "Barua",
        extension: "",
        email: "masdasd@gmail.com",
        contact_no: "0923232323",
        birth_place: "Ormoc",
        gender: "Male",
        citizenship: "Filipino",
        civil_status: "Single",
        course_id: "SAasdas",
      },
      applicants: [],
      meta: {
        page: 1,
        pages: 1,
        total: 0,
        per_page: 10,
      },
      importForm: null,
      selected: {},
      results: {
        results_file: null,
      },
      form: {},
      showDetails: false,
      componentKey: 0,
    };
  },
  created: function () {
    this.onSearch();
  },
  methods: {
    addApplicant(){
      this.showDialog = true;
    },
    getAlertStatus(status) {
      let index = 0
      this.$store.state.final_status.forEach(function(final, i){
        if (final == status) index = i;
      })
      return  this.$store.state.alert_status[index]
    },
    showGender(item) {
      let string = ""
      this.$store.state.genders.forEach(function(gender){
        if (gender.id == item.gender ) {
          string = gender.value
        }
      })

      return string
    },
    ShowCitizenship(item) {
      let string = ""
      this.$store.state.citizenships.forEach(function(c){
        if (c.id == item.citizenship ) {
          string = c.value
        }
      })

      return string
    },
     showCivilStatus(item) {
      let string = ""
      this.$store.state.statuses.forEach(function(s){
        if (s.id == item.civil_status ) {
        string = s.value
        }
      })

      return string
    },
    onSearch() {
      this.meta.page = 1;
      this.loading = true;
      this.getApplicants();
    },
    getApplicants() {
      Applicant.get({
        params: { exam_mode: 'FACE TO FACE', page: this.meta.page, term: this.searchForm.name },
        
      })
        .then((result) => {
          this.applicants = result.data.data;
          this.loading = false;
          // console.log(result.data.data);
          this.meta = {
            page: result.data.meta.current_page,
            total: result.data.meta.total,
            per_page: result.data.meta.per_page,
            pages: result.data.meta.last_page,
          };
          this.loading = false;
          // console.log(result.data.data);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          // this.applicants = [];
        });
    },
    pageChanged(val) {
      this.loading = true;
      this.meta.page = val;
      this.getApplicants();
    },
    exportApplicants(campus) {

      Applicant.exportCsv({
        campus: campus.toLowerCase()
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'applicants_'+ campus +'.csv');
        document.body.appendChild(link);
        link.click();
      });
    },
    unlock(id) {
      Applicant.unLock(id)
        .then((response) => {
          location.reload();
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.$message({
              message: error.response.data.message,
              type: "error",
              duration: 3000,
            });
          }
        });
    },
    uploadResults() {
      Applicant.importf2f(
          this.results
      )
      .then(result=> {
         console.log("success!");
        this.loading = false;
        this.$message({
            message: 'Imported successfully.',
            type: 'success',
            duration: 5000
        }); 
      }).catch(error => {
        console.error(error)
        this.$message({
            message: 'Unable to upload the file.',
            type: 'error',
            duration: 5000
          }); 
      })
    },
    handleClose(){
      this.$confirm('Are you sure to cancel adding applicant? this will delete your current inputs')
        .then(_ => {
          this.showDialog = false;
          done();
        })
        .catch(_ => {});
    },
    done(){
      this.showDialog = false;
      this.getApplicants();
    },
    handleCurrentChange(val) {
      let data = null;
      this.reloadComponent();
      if(val){
        this.currentRow = val;
        this.showDetails = true;
        data = Object.assign({}, this.currentRow);
        this.selected = data;
        console.log(this.currentRow.id);
      }
        
    },
    reloadComponent() {
      this.componentKey += 1;
    },
  },
};
</script>