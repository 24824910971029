<template>
     <div>
      <h3 style="text-align:center">PRIVACY NOTICE FOR COLLEGE ADMISSION APPLICANTS</h3>
        <p>
          The Visayas State University is committed to securing and protecting all the personal data 
          that you will share with the university as you pursue your education here with us. 
          As a general principle, all the personal data that we will collect from you will be 
          kept securely and will only be used for their intended purpose, 
          in accordance with the Data Privacy Act of 2012.
        </p>
        <p>
          Specific terms and conditions on collection, processing or use, storage, disclosure, 
          and disposal of your personal data and information can be found on this 
          link <a href="https://www.vsu.edu.ph/about/privacy" class="blue">https://www.vsu.edu.ph/about/privacy</a> .
          By continuing with your application, you consent to VSU in processing any data that you will 
          submit for your application for admission, including the publishing of your name on our 
          website should you qualify for the 2024 VSU College Admission Screening. Please download 
          this document here and affix your signature to proceed with your application for the 
          VSU-College Admission. Upload the signed document to obtain your examination schedule.
        </p>


<h4 style="text-align:center">CONSENT FORM</h4>
<h4 style="text-align:center">TERMS OF AGREEMENT FOR APPLICANTS FOR ADMISSION</h4>


<p><b>Under 18 years old:</b></p>
<p>I _________________ parent or acting as the legal guardian of _______________________
who is currently applying for admission/enrollment into Visayas State University (VSU), Baybay Campus or  _____________ Component College, ____ DO  ___ DO NOT give consent to VSU to collect and process our personal data in relation to the purpose of </p>
   <p> 1.)admission/enrollment/scholarship/counseling to the university, </p>
   <p> 2.)including the publishing of the name of applicant and the degree program at the VSU website and/or Official VSU Facebook page in the event that the applicant qualify for admission in order for VSU to uphold the principle of transparency in the admissions process.
</p>
<p><b>18 years old or above</b></p>
<p>I ____________________ of legal age and is currently applying for admission/enrollment into Visayas State University (VSU), Baybay Campus or  _____________ Component College  ___ DO  ___ DO NOT give consent to VSU to collect and process my personal data in relation to the purpose of   1.) admission/enrollment/scholarship/counseling to the university,   2.) including the publishing of my name and program on the VSU website and/or Official VSU Facebook page in the event I qualify for admission in order for VSU to uphold the principle of transparency in the admissions process.
</p>
        <h4>Collection: </h4>
        <p>
          Applicant/Student - Personal Data which includes name, active mobile number, home address, 
          sex/gender, age, date of birth, place of birth, civil status, name of spouse (if married), 
          number of children, citizenship, email address, social categories, parents 
          (name, age, citizenship, highest educational attainment, occupation, monthly income), 
          schools attended (elementary, junior high school, senior high school, vocational, college, 
          name of schools, municipality and province, category (public/private), school year attended or graduated), 
          GPAs obtained (Grades 9, 10 and 11), preferred VSU campus, preferred undergraduate degree program. 
        </p>

        <p>
          Person to be notified in case of emergency - Personal Data which includes name, relationship to the applicant, 
          address, and contact number. 
        </p>

        <h4>Processing or Use</h4>

        <p>
          The personal data collected about the applicant/student is used and processed in the following manner:
        </p>

        <ul>
            <li> Verification process for taking the college admission test </li>
            <li> Determining the admissions and enrollment status of the applicant </li>
            <li>Complying the affirmative admission policy for children of underprivileged families and other disadvantaged sector of society per BOR Resolution No. 20 Series of 2019.</li>  
            <li>Protection of the health and vital interest of the applicant/student thru medical or health evaluation </li>
            <li>Preparation processes for onboarding activities for flexible learning such as orientation events of the various programs and colleges </li>
            <li>Setting up or processing needed in academic systems such as Learning Management Systems </li>
            <li>Internal process improvement activities that may include the use of data analytics to process the personal data </li>
            <li>Use in emergency situations to protect the vital and medical interest of the applicant/student </li>
        </ul>

        <p>The personal data collected about the Guardian is used and processed in the following manner: </p>

        <ul>
          <li>Use in emergency situations to protect the vital and medical interest of the applicant/student </li>
        </ul>
        <p>The personal data collected about the Guardian of Applicant/Student is used and processed in the following manner: </p>
        <ul>
          <li>All process of a Legal Guardian. </li>
          <li>Used in conjunction with financial related documents to determine the qualification to the scholarship program. </li>
        </ul>

        <h4>Storage:  </h4>

        <p>The personal data of both the applicant/student and the guardian are stored in the following manner: </p>
        <ul>
          <li>The physical document collected are stored under lock and key in secure storage locations such as steel cabinets and can only be opened by authorized personnel. Archival data are stored externally in secure storage warehouses. </li>
          <li>The electronic version of the personal data whether digitized or transcribed are stored in university information systems that are 
            housed within the university premise mostly with some information stored externally in service providers 
            for efficiency as well as for business continuity purposes. Only authorized personnel are given the rights
             to access the data thru various access control mechanisms. </li>
        </ul>

        <h4>Disclosure: </h4>

        <p>The personal data of the applicant/student are shared with the following units within the university for the previously stated purposes </p>

        <ul>
          <li>University Legal Office - Data Protection Officer (DPO) </li>
          <li>Information, Communication and Technology Management Center (ICTMC) for exam and evaluation purposes as well as for conducting internal improvement surveys and research </li>
          <li>Technical Working Group for the generation of the list of admission qualifiers</li>
          <li>University Admissions Committee </li>
          <li>Office of the President for the final review and approval of the list of admission qualifiers</li>
          <li>University Integrated Media Center (UIMC) for posting list of qualifiers</li>
          <li>Office of the University Registrar (OUR)</li>
          <li>University Services for Health and Emergency Rescue for medical or health related requirements </li>
          <li>Colleges and Departments relevant to the degree program of the applicant/student </li>
          <li>Office of the Dean of Student (ODS) for UNIFAST, counseling and career related activities</li>
          <li>University Library for monitoring of library usage </li>
          <li>University Security and Discipline office for maintaining security to the university </li>
          <li>Aggregated data may be generated from your personal data for study and reporting purposes</li>
          <li>Parents or Legal Guardian of the applicant/student for information purposes </li>
          <li>Personal Data SHALL NOT be shared with parties not currently stated without prior additional consent from the data subject </li>
        </ul>

        <p>The personal data of the legal guardian are shared with the following units within the university for the previously stated purposes </p>

        <ul>
          <li>University Legal Office - Data Protection Officer (DPO) </li>
          <li>Information, Communication and Technology Management Center (ICTMC) for exam and evaluation purposes as well as for conducting internal improvement surveys and research </li>
          <li>Technical Working Group for the generation of the list of admission qualifiers</li>
          <li>
            Colleges and Departments relevant to the program or degree of the applicant/student 
          </li>
          <li>University Security and Discipline office for maintaining security to the university </li>
          <li>Aggregated data may be generated from your personal data for study and reporting purposes </li>
          <li>Applicant/Student for information purposes </li>
          <li>Personal Data SHALL NOT be shared with parties not currently stated without prior additional consent from the data subject </li>
        </ul>

<h4>Retention Period: </h4>
        <p>
Visayas State University retains data in accordance with the Records and Archives Office (RAO) Quality Procedure on Disposition of Records. In the absence of an applicable rule of retention, Personal Data shall be retained in accordance with the practices of government bodies with analogous functions.
</p>
<h4>Basic Rights of the Data Subject: </h4>
<p>[Source: RA 10173 <a href="https://privacy.gov.ph/data-privacy-act/">https://privacy.gov.ph/data-privacy-act/ </a>] </p>

<p>1.	As the Data Subject, you are afforded the following rights as written in RA 10173 otherwise known as the Data Privacy Act of 2012. </p>

<p>2.	The right to be informed giwhether personal information pertaining to him or her shall be, are being or have been processed. </p>

<p>3.	The right to be furnished with the information before the entry of his or her personal information into the processing system of the personal information controller. </p>

<p>4.	The right to reasonable access to the data subjects information upon demand. </p>

<p>5.	The right to dispute the inaccuracy or error in the personal information and have the personal information controller correct it immediately and accordingly, unless the request is vexatious or otherwise unreasonable. </p>

<p>6.	The right to suspend, withdraw or order the blocking, removal or destruction of his or her personal information from the personal information controller, filing system upon discovery and substantial proof that the personal information are incomplete, outdated, false, unlawfully obtained, used for unauthorized purposes or are no longer necessary for the purposes for which they were collected. </p>

<p>7.	The right to be indemnified for any damages sustained due to such inaccurate, incomplete, outdated, false, unlawfully obtained or unauthorized use of personal information. </p>

<p>8.	The right to data portability thru the ability to obtain from the personal information controller a copy of data undergoing processing in an electronic or structured format, which is commonly used and allows for further use by the data subject. </p>

    </div>
</template>

<script>

export default {
  name: 'TermsAndComponent'
}
</script>

<style scoped>

.red {
  color: #f44336 !important
}

.yellow {
  color: #ffcc00 !important;
}

.blue {
  color: #1658ea !important;
}

p {
  text-align: justify !important;
  padding-top: 10px !important;
}

p, ul li {
  font-size: 12px !important;
}
</style>