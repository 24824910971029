<template>
    <div >
        <el-container style="border: solid 1px #a1a1a1; margin-bottom: 50px;">
            <el-header style="background-color: #86acbb;color: #333;text-align: center; height: 40px !important; padding: 10px;">Previous Schedules</el-header>
            <el-main style="padding: 0px">
                <el-table :data="prev_scheds" border size="small">
                <el-table-column
                    label="Schedule" align="center">
                    <template slot-scope="scope">
                    {{ scope.row.schedule }}
                    </template>
                </el-table-column>
                
                <el-table-column
                    label="Score" width="80">
                    <template slot-scope="scope">
                    {{ scope.row.cat_score ? scope.row.cat_score:"-" }}
                    </template>
                </el-table-column>

                <el-table-column
                    label="Remarks" width="150">
                    <template slot-scope="scope">
                    {{ scope.row.exam_remark }}
                    </template>
                </el-table-column>

                <el-table-column
                    label="Exam status" width="150">
                    <template slot-scope="scope">
                    {{ scope.row.exam_status }}
                    </template>
                </el-table-column>

                <el-table-column
                    label="Zoom Status" width="150">
                    <template slot-scope="scope">
                    {{ scope.row.zoom_status }}
                    </template>
                </el-table-column>
                
                </el-table>
            </el-main>
        </el-container>
    </div>
  </template>
  
  <script>
  import { Applicant } from '@/api_services/applicant'
  export default {
    name: "UpdateEmailComponent",
    props: ['applicant'],
  
    data() {
      return {
        prev_scheds: []
      };
    },
    created() {
      
      this.prev_scheds = this.applicant.prev_scheds;
    console.log(this.prev_scheds)
    },
    methods: {
      async update() {

      }
    },
  };
  </script>
  