<template>
    <div>
      <el-form
      :model="form"
        ref="form"
        label-width="50px"
        :rules="rules"
      >
        <el-form-item label="GPA" prop="hs_type_gpa">
          <el-input type="number" v-model="form.hs_type_gpa" style="width: 100%;"></el-input>
        </el-form-item>
  
        <el-form-item>
          <el-button type="primary" @click="update" style="width: 100%;">Update</el-button>
          <!-- <el-button @click="resetForm">Reset</el-button> -->
        </el-form-item>
      </el-form>
    </div>
  </template>
  
  <script>
  import { Applicant } from '@/api_services/applicant'
  export default {
    name: "UpdateGpaComponent",
    props: ["selected"],

  
    data() {
      const checkGPA = (rule, value, callback) =>{
        
        const numericValue = parseFloat(value);

        if (!isNaN(numericValue) && numericValue >= 60 && numericValue <= 100) {
          callback()
        } else {
          callback(new Error('Input not allowed. Accepted inputs: 60-100'))
        }

      };
      return {
        form: this.selected,
        rules: {
          hs_type_gpa: [
            { validator: checkGPA, trigger: 'blur' },
          ],
        },
      };
    },
    created() {
      this.form = this.selected;
      // console.log(this.form);
    },
    methods: {
      async update() {
        const valid = await this.$refs.form.validate();
        if (!valid) return
        this.$confirm('Are you sure you want to update the GPA of <strong>' + this.form.firstname + ' ' + this.form.lastname +'</strong> . Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
          dangerouslyUseHTMLString: true,
        }).then(() => {
          Applicant.updateGpa(this.form)
            .then((response) => {
              this.$emit("saved", response.data.data);
              this.$message({
                type: 'success',
                message: 'GPA updated'
              });
              // this.$router.go(0);
            }).catch((error) => {
              this.$message({
                message: error.response.data.message,
                type: "error",
                duration: 3000,
              });
            });
          
        }).catch(() => {

        });
      }
    },
  };
  </script>
  