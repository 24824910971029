<template>
    <div>
        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <el-card>
                    <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                        <el-form-item>
                            <span>Filter:</span>
                        </el-form-item>
                        <el-form-item>
                            <el-input width="200"
                            v-model="searchForm.name"
                            placeholder="code / major / description"
                            size="small"
                            ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-select
                            v-model="searchForm.campus"
                            placeholder="Campus"
                            size="small"
                            >
                            <el-option label="All" value=""></el-option>
                            <el-option :label="campus.value" :value="campus.id" v-for="campus in $store.state.campuses" :key="campus.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button
                            type="success"
                            @click="onSearch"
                            size="small"
                            :loading="loading"
                            icon="el-icon-search"
                            >Search</el-button
                            >
                        </el-form-item>
                        <el-form-item>
                            <el-button
                            type="info"
                            @click="clear"
                            size="small"
                            :loading="loading"
                            icon="el-icon-delete"
                            >Clear Filter</el-button
                            >
                        </el-form-item>
                    </el-form>
                    <div style="margin-top: -12px; text-align: right;">
                        <span style="color: #797979; font-size: 13px;">Total: </span><span style="color: #797979; font-size: 16px;"><b>{{ meta.total }}</b></span>
                    </div>
                    <el-table :data="courses" 
                    highlight-current-row
                    class="highlightedTable"
                    @current-change="handleCurrentChange"
                    v-loading="loading"
                    size="mini"
                    stripe style="width: 100%; cursor: pointer">
                        <el-table-column prop="id" label="ID" width="50"></el-table-column>
                        <el-table-column label="Degree Program" width="280">
                            <template slot-scope="scope">
                                <div class="custom-cell">
                                    {{ formatCampus(scope.row.description) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="major" label="Major"></el-table-column>
                        <el-table-column label="Campus">
                            <template slot-scope="scope">
                                <div class="custom-cell">
                                    {{ formatCampus(scope.row.campuses) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot-scope="scope">
                                <el-button icon="el-icon-s-data" style="width: 100%;" v-if="!scope.row.has_ranking" type="warning" size="mini" @click="generateInitialRanking(scope.row)">Rank</el-button>
                                <el-button icon="el-icon-s-data" style="width: 100%;" v-if="scope.row.has_ranking" type="success" size="mini" @click="generateInitialRanking(scope.row)">Re-rank</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="margin: auto; text-align: center;">
                        <el-pagination
                            class="margin-top-20"
                            background
                            layout="prev, pager, next"
                            :total="meta.total"
                            :page-size="15"
                            @current-change="pageChanged"
                            :current-page="meta.page">
                        </el-pagination>
                    </div>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <el-card>
                    <div v-if="!selected_program" style="text-align: center;">
                        <h2 style="color: #559f30;">Select a Degree Program</h2>
                    </div>
                    <div v-if="selected_program" style="text-align: center;">
                        <h2 style="color: #559f30; margin-bottom: -10px;">{{ selected_program.code }} - {{ selected_program.description }}</h2>
                        <div v-if="meta_selected.total > 0" style="text-align: right;">
                            <el-button style="width: 15%;" :loading="loading_export"  type="warning" size="small" icon="el-icon-printer"  @click="exportRanks(selected_program)">Export</el-button>
                            <el-button style="width: 15%;" :loading="loading_rank"  type="success" size="small" icon="el-icon-brush"  @click="saveRanks(selected_program)">Save Tags</el-button><br>
                            <!-- *<i style="font-size: 12px; color: #909399;">Temporary Tags will be saved as <br>their Final Tag for Pre-enrollment</i> -->
                        </div>
                        <el-tabs type="card" v-model="activeTab" @tab-click="changeTab">
                            <el-tab-pane v-for="(campus, index) in selected_program.selected_campuses" :key="index" :label="campus">
                                <div style="text-align: left;">
                                    <span style="color: #797979; font-size: 13px;">Quota: </span><span>{{ selected_program.quotas ? selected_program.quotas[index] : "Not set" }}</span> 
                                    <span style="color: #797979; font-size: 13px; margin-left: 10px;"> Cut Off: </span><span> {{ selected_program.campus_cut_off }}</span>
                                    <el-form :model="searchFormRank" >
                                        <div style="border: solid 1px #cfcfcf; margin-top: 15px; margin-bottom: 15px; padding: 10px;">
                                                <span>Filter:</span>
                                                <div style="text-align: left; margin-bottom: 10px; margin-top: 10px;">
                                                    <el-input style="width: 30%; margin-right: 10px;"
                                                        v-model="searchFormRank.name"
                                                        placeholder="ECN / Name / email"
                                                        size="small">
                                                    </el-input>
                                                    <el-select style="width: 30%; margin-right: 10px;"
                                                        v-model="searchFormRank.temp_tag"
                                                        placeholder="Initial Tag"
                                                        size="small">
                                                        <el-option label="All" value=""></el-option>
                                                        <el-option :label="tag" :value="tag" v-for="tag in $store.state.tag" :key="tag"></el-option>
                                                    </el-select>
                                                    <el-select style="width: 30%;"
                                                        v-model="searchFormRank.final_tag"
                                                        placeholder="Final Tag"
                                                        size="small">
                                                        <el-option label="All" value=""></el-option>
                                                        <el-option :label="tag" :value="tag" v-for="tag in $store.state.tag" :key="tag"></el-option>
                                                    </el-select>
                                                </div>
                                                
                                                <div style="text-align: left; margin-bottom: 10px;">
                                                    <el-select style="width: 30%; margin-right: 10px;"
                                                        v-model="searchFormRank.has_social_category"
                                                        placeholder="Has Social Category?"
                                                        size="small">
                                                        <el-option label="All" value=""></el-option>
                                                        <el-option :label="opt" :value="opt" v-for="opt in $store.state.yes_no_opt" :key="opt"></el-option>
                                                    </el-select>
                                                    <!-- <el-select style="width: 30%;"
                                                        v-model="searchFormRank.has_same_name"
                                                        placeholder="Has Same Name?"
                                                        size="small">
                                                        <el-option label="All" value=""></el-option>
                                                        <el-option :label="opt" :value="opt" v-for="opt in $store.state.yes_no_opt" :key="opt"></el-option>
                                                    </el-select> -->
                                                </div>
                                                <div style="text-align: right;">
                                                    <el-button style="width: 15%;"
                                                        type="success"
                                                        @click="onSearchRank"
                                                        size="small"
                                                        :loading="loading"
                                                        icon="el-icon-search">Search
                                                    </el-button>
                                                    <el-button style="width: 15%;"
                                                        type="info"
                                                        @click="clearRank"
                                                        size="small"
                                                        :loading="loading"
                                                        icon="el-icon-delete">Clear Filter
                                                    </el-button>
                                                </div>
                                                
                                        </div>
                                    </el-form>
                                </div>
                                <div style="text-align: right;">
                                    <span style="color: #797979; font-size: 13px;">Total: </span><span style="color: #797979; font-size: 16px;"><b>{{ meta_selected.total }}</b></span>
                                </div>
                                <el-table :data="ranked_applicants"
                                size="mini"
                                v-loading="loading_rank"
                                stripe style="width: 100%; cursor: pointer;">
                                    <el-table-column prop="control_no" label="ECN" width="70"></el-table-column>
                                    <el-table-column label="Name" width="270">
                                        <template slot-scope="scope">
                                            
                                            <div class="custom-cell">
                                                
                                                {{ scope.row.full_name }}<br>
                                                <span v-if="scope.row.has_dup" style="color: #ffffff; background-color: #ff6a6a; padding: 2px; border-radius: 5px;">Has same name</span>
                                                
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Rank / Rating / SC" width="150">
                                        <template slot-scope="scope">
                                            <b>{{ scope.row.rank }}</b><span style="color: #797979; font-size: 12px"> / {{ scope.row.final_rating }}</span> <span v-if="scope.row.sc_tag" style="color: #909399;">/ <b>Yes</b></span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Initial / Final" >
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.temp_tag == 'ACCEPTED'" style="background-color: #559f30; color: #ffffff; font-size: 10px; padding: 1px; border-radius: 5px;">{{ scope.row.temp_tag ? scope.row.temp_tag : "--"}}</span> 
                                            <span v-else-if="scope.row.temp_tag == 'QUALIFIED'" style="background-color: #797979; color: #ffffff; font-size: 10px; padding: 1px; border-radius: 5px;">{{ scope.row.temp_tag ? scope.row.temp_tag : "--"}}</span> 
                                            <span v-else style="color: #797979; font-size: 10px; padding: 1px; border-radius: 5px;">{{ scope.row.temp_tag ? scope.row.temp_tag : "--"}}</span>
                                            /<br>
                                            <span v-if="scope.row.final_tag == 'ACCEPTED'" style="background-color: #559f30; color: #ffffff; font-size: 10px; padding: 1px; border-radius: 5px;">{{ scope.row.final_tag ? scope.row.final_tag : "--"}}</span>
                                            <span v-else-if="scope.row.final_tag == 'QUALIFIED'" style="background-color: #797979; color: #ffffff; font-size: 10px; padding: 1px; border-radius: 5px;">{{ scope.row.final_tag ? scope.row.final_tag : "--"}}</span>
                                            <span v-else style="color: #797979; font-size: 10px; padding: 1px; border-radius: 5px">{{ scope.row.final_tag ? scope.row.final_tag : "--"}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="90">
                                        <template slot-scope="scope">
                                            <el-button icon="el-icon-edit" style="width: 40%; padding: 2px;" type="warning" size="mini" @click="editTag(scope.row)"></el-button>
                                            <el-button icon="el-icon-view" style="width: 40%; padding: 2px;" type="info" size="mini" @click="viewApplicant(scope.row.applicant_id)"></el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div style="margin: auto; text-align: center;">
                                    <el-pagination
                                        class="margin-top-20"
                                        background
                                        layout="prev, pager, next"
                                        :total="meta_selected.total"
                                        :page-size="15"
                                        @current-change="pageChangedSelected"
                                        :current-page="meta_selected.page">
                                    </el-pagination>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>

                </el-card>
            </el-col>
        </el-row>

        <el-dialog title="Edit Tag" width="30%" :visible.sync="showTagDetails" v-if="showTagDetails" :destroy-on-close.sync="destroyOnClose" append-to-body>
            <edit-tag-component :selected.sync="selected" @saved="done"></edit-tag-component>
        </el-dialog>    
        
    </div>
    
  </template>

  <script>
  import { Course } from "@/api_services/course";
  import { Ranking } from "@/api_services/ranking";
  import { Setting } from "@/api_services/setting";
  import EditTagComponent from '@/views/private/pages/results/EditTagComponent.vue';

  export default {
    components: {EditTagComponent},
    data() {
      return {
        loading: false,
        courses: [],
        meta: {
            page: 0,
            pages: 0,
            total: 0,
            per_page: 15,
        },
        meta_selected: {
            page: 0,
            pages: 0,
            total: 0,
            per_page: 15,
        },
        ranked_applicants: null,
        selected_program: null,
        selected_campus: null,
        loading_rank: false,
        activeTab: 0,
        searchForm: {},
        searchFormSelected: {},
        showTagDetails: false,
        destroyOnClose: true,
        selected: null,
        loading_export: false,
        main_cut_off: null,
        tolosa_cut_off: null,
        alangalang_cut_off: null,
        isabel_cut_off: null,
        villaba_cut_off: null,
        searchFormRank: {},
        temp_val: null
      }
    },
    created() {
        this.onSearch();
        this.getCampusCutOffs();
    },
    methods: {
        formatCampus(campusString) {
            if (campusString) {
                return campusString.split(',').join(' / ');
            } else {
                return campusString; 
            }
        },
        exportRanks(course){
            this.$confirm('Download CSV file for <strong>' + course.code + ' from ' + course.campuses +' campuse(s)</strong>?', 'Download file', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning',
                dangerouslyUseHTMLString: true,
            }).then(() => {
                this.loading_export = true
                Ranking.exportCsvList({
                    course_id: course.id
                }).then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    const major = course.major ? course.major : "";
                    const campuses = course.campuses.replace(/,/g, "_");
                    link.setAttribute('download', 'ranking_'+ course.code + '_' + major + '_' + campuses + '.csv');
                    document.body.appendChild(link);
                    link.click();
                    this.loading_export = false
                }).catch((error) => {
                    console.log(error)
                    this.loading_export = false;
                });
            }).catch(() => {
                
            });
            
        },
        saveRanks(course){
            this.$confirm('Save Initial Tags into Final Tags for <strong>' + course.code + ' from ' + course.campuses +' campuse(s)</strong>?<br><br>Note: Final Tags will be used for Pre-enrollment Process.', 'Save Final Tags', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning',
                dangerouslyUseHTMLString: true,
            }).then(() => {
                this.loading_rank = true;
                Ranking.saveTags({ 
                    courseid: course.id 
                }).then((result) => {
                    this.loading_rank = false;
                    this.clear()
                    console.log("result", result.data);
                }).catch((error) => {
                    this.loading_rank = false;
                    console.log(error);
                });
            }).catch(() => {
                            
            });
        },
        onSearch() {
            this.meta.page = 1
            this.loading = true
            this.getCourses()
        },
        pageChanged(val){
            this.loading = true;
            this.meta.page = val;
            this.getCourses();
        },
        pageChangedSelected(val){
            this.loading_rank = true;
            this.meta_selected.page = val;
            this.getRank(val, this.selected_program.id, this.selected_campus)
        },
        clear(){
            this.searchForm.name = null
            this.searchForm.campus = null
            this.getCourses();
        },
        getCourses() {
            this.selected_program = null
            this.loading = true
            Course.get({
                params: { page: this.meta.page, term: this.searchForm.name, campus: this.searchForm.campus},
            })
                .then((result) => {
                this.courses = result.data.data;
                console.log("courses", this.courses)
                this.meta = {
                    page: result.data.meta.current_page,
                    total: result.data.meta.total,
                    per_page: result.data.meta.per_page,
                    pages: result.data.meta.last_page,
                };
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        handleCurrentChange(val) {
            this.activeTab = '0'
            console.log(val)
            if(val){
                this.temp_val = val
                this.searchFormRank = {}
                this.selected_program = val
                const campusesArray = this.selected_program.campuses.split(',')

                if(this.selected_program.quota){
                    const quotasArray = this.selected_program.quota.split(',')
                    this.selected_program.quotas = quotasArray
                } else{
                    this.selected_program.quotas = null
                }
                

                this.selected_program.selected_campuses = campusesArray
                this.selected_campus = campusesArray[this.activeTab]

                
                // console.log(this.selected_program)
                this.meta_selected.page = 1
                this.getRank(this.meta_selected.page, this.selected_program.id, this.selected_campus)
            }
        },
        changeTab(){
            const campusesArray = this.selected_program.campuses.split(',');
            this.selected_campus = campusesArray[this.activeTab];
            this.meta_selected.page = 1
            this.getRank(this.meta_selected.page, this.selected_program.id, this.selected_campus)
        },
        getRank(page, courseid, campus){
            console.log(courseid ,  campus )
            this.ranked_applicants = null
            this.loading_rank = true

            switch (campus) {
                case "main":
                    this.selected_program.campus_cut_off = this.main_cut_off;
                    break;
                case "tolosa":
                    this.selected_program.campus_cut_off = this.tolosa_cut_off;
                    break;
                case "alangalang":
                    this.selected_program.campus_cut_off = this.alangalang_cut_off;
                    break;
                case "isabel":
                    this.selected_program.campus_cut_off = this.isabel_cut_off;
                    break;
                case "villaba":
                    this.selected_program.campus_cut_off = this.villaba_cut_off;
                    break;
                default:
                    this.selected_program.campus_cut_off = 0;
            }


            Ranking.get({ 
                params: { 
                    page: page, 
                    courseid: courseid, 
                    campus: campus, 
                    term: this.searchFormRank.name, 
                    temp_tag: this.searchFormRank.temp_tag,
                    final_tag: this.searchFormRank.final_tag,
                    has_social_category: this.searchFormRank.has_social_category,
                    // has_same_name: this.searchFormRank.has_same_name,
                },
            })
            .then((result) => {
                this.loading_rank = false
                this.ranked_applicants = result.data.data
                this.meta_selected = {
                    page: result.data.meta.current_page,
                    total: result.data.meta.total,
                    per_page: result.data.meta.per_page,
                    pages: result.data.meta.last_page,
                };
                console.log(result)
            })
            .catch((error) => {
                this.loading = false;
                console.log(error);
            });
        },
        generateInitialRanking(course){
            this.$confirm('Are you sure you want to rank results for <strong>' + course.code + ' from ' + course.campuses +' campuse(s)</strong>? <br><br>Please note that if there are previous rankings / taggings for this course, they will be overridden.', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning',
                dangerouslyUseHTMLString: true,
            }).then(() => {

                this.loading_rank = true;
                Ranking.generateRanking({ 
                    courseid: course.id 
                })
                .then((result) => {
                    
                    this.loading_rank = false;
                    console.log("result", result.data)
                    this.getCourses()
                    this.handleCurrentChange(this.temp_val)
                })
                .catch((error) => {
                    this.loading_rank = false;
                    console.log(error);
                });
            }).catch(() => {
                
            });
        },
        viewApplicant(id){
            window.focus();
            let routeData = this.$router.resolve({name: 'SelectedApplicant', params: { id: id }});
            window.open(routeData.href, '_blank')
        },
        editTag(item){
            this.selected = Object.assign({}, item);
            this.showTagDetails = true
        },
        done(){
            this.pageChangedSelected(this.meta_selected.page)
            this.showTagDetails = false
        },
        getCampusCutOffs(){
            Setting.getOthers({
            }).then((result) => {
                this.main_cut_off =  result.data.main
                this.tolosa_cut_off =  result.data.tolosa
                this.alangalang_cut_off =  result.data.alangalang
                this.isabel_cut_off =  result.data.isabel
                this.villaba_cut_off = result.data.villaba
                this.sc_percentage = result.data.sc_percentage

                this.loading = false
            }).catch((error) => {

            });
        },
        onSearchRank(){
            const campusesArray = this.selected_program.campuses.split(',');
            this.selected_campus = campusesArray[this.activeTab];
            this.meta_selected.page = 1
            this.getRank(this.meta_selected.page, this.selected_program.id, this.selected_campus)
        },
        clearRank(){
            this.searchFormRank = {}
            const campusesArray = this.selected_program.campuses.split(',');
            this.selected_campus = campusesArray[this.activeTab];
            this.meta_selected.page = 1
            this.getRank(this.meta_selected.page, this.selected_program.id, this.selected_campus)
        },
    }
  };
</script>