<template>
    <div style="padding: 20px">
      <el-form ref="form" class="applicant-step-forms" :model="form" :rules="rules">
        <el-divider content-position="left">Other Information</el-divider>

         <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-form-item label="Campus" prop="campus">
              <el-select v-model="form.campus" placeholder="Campus" size="large" style="width: 100%;">
                <el-option :label="campus.value" :value="campus.id" v-for="campus in $store.state.campuses" :key="campus.id"></el-option>
              </el-select>
            </el-form-item>
            
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-form-item label="Course" prop="course">
              <search-course-component :course.sync="form.course" style="width: 100%;" :campus="form.campus" ></search-course-component>
            </el-form-item>
          </el-col>
         </el-row>

        
         <br>
         <el-row :gutter="20">
           <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
             <el-form-item label="Latest Education Attainment" prop="hs_type">
                <el-select v-model="form.hs_type" size="large" style="width: 100%;" @change="handleCategoryChange">
                  <el-option :label="hs_type.value" :value="hs_type.id" v-for="hs_type in $store.state.hs_types" :key="hs_type.id"></el-option>
                </el-select>
            </el-form-item>
           </el-col>
           <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
             <el-form-item label="Obtained GPA or Rating" prop="hs_type_gpa">
                <el-input placeholder="0.00" v-model="form.hs_type_gpa" type="number" :readonly="form.hs_type == 1"></el-input>
            </el-form-item>
           </el-col>
         </el-row>

          <el-row :gutter="20" v-if="form.hs_type === 1 || form.hs_type === 2">
            <el-card style="background-color: #f7f7f7;" shadow="none">
              <div v-if="form.hs_type === 1">
                <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                  <el-form-item label="Grade 9 GPA" prop="grade_9_gpa">
                      <el-input placeholder="0.00" v-model="form.grade_9_gpa" type="number" @change="handleCategoryChange"></el-input>
                  </el-form-item>
                </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                  <el-form-item label="Grade 10 GPA" prop="grade_10_gpa">
                      <el-input placeholder="0.00" v-model="form.grade_10_gpa" type="number" @change="handleCategoryChange"></el-input>
                  </el-form-item>
                </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                  <el-form-item label="Grade 11 GPA" prop="grade_11_gpa">
                      <el-input placeholder="0.00" v-model="form.grade_11_gpa" type="number" @change="handleCategoryChange"></el-input>
                  </el-form-item>
                </el-col>
              </div>
              <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                <el-form-item label="SHS Strand" prop="strand"><!-- TODO: select input -->
                  <el-select style="width: 100%" :key="select_strand_key" :disabled="is_other_strand" filterable v-model="form.strand" value-key="id"  placeholder="SHS Strand" size="large" class="strand-select">
                    <el-option :label="truncateText(strand.strand_code + ' - ' + strand.strand_description, 35)" :value="strand" v-for="strand in strands" :key="strand.id"></el-option>
                  </el-select>
                  <!-- for others checkbox -->
                  <!-- <el-checkbox v-model="is_other_strand" @change="handleChangeStrand()" style="margin-left: 10px;">Others</el-checkbox> -->
                </el-form-item>
              </el-col>
              <!-- <el-col v-if="is_other_strand" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-row :gutter="10">
                  <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5">
                    <el-form-item prop="other_strand_code">
                      <el-input v-model="form.other_strand_code" placeholder="Strand code" type="text" style="width: 100%"/>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <el-form-item prop="other_strand_desc">
                      <el-input v-model="form.other_strand_desc" placeholder="Strand Description" type="text" style="width: 100%"/>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col> -->
            </el-card>
          </el-row>

          <br>
          <el-row :gutter="20">
           <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
             <el-form-item label="Social Categories" prop="social_categories" style="margin-bottom:0px"></el-form-item>
             <el-checkbox style="margin: 5px" @change="handleSocialCategoriesChange($event, index)" v-model="social_categories[index]" :label="social" v-for="(social,index) in $store.state.social_category_names" :key="social">{{social}} 
                </el-checkbox>  
           </el-col>
           <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16" v-if="form.social_categories && form.social_categories[4]">
             <el-form-item label="IF PWD, What is your physical disability/handicap?" prop="pwd_handicap">
                <el-input placeholder="" v-model="form.pwd_handicap" type="text"></el-input>
            </el-form-item>
           </el-col>
          </el-row><br>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" >
             <el-form-item label="Any allergies or health problem?" prop="health_problems">
                <el-input placeholder="" v-model="form.health_problems" type="text"></el-input>
            </el-form-item>
           </el-col>
          </el-row>
          <br>
          <div v-if="form.gender === 'F'">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
              <el-form-item label="Are you the first female in your family to attend college? " style="margin-bottom:0px" prop="is_first_female">
                <el-select v-model="form.is_first_female" value-key="id">
                  <el-option label="No" value="No"></el-option>
                  <el-option label="Yes" value="Yes"></el-option>
                </el-select>
              </el-form-item><br>
              <span style="font-size: 12px; color: #626262;">This refers to all the female students who are the first in their immediate family (parents and siblings)  </span>
              
            </el-col>
          </el-row>
          </div>
          <div v-if="form.gender === 'M'">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
              <el-form-item label="Are you the first male in your family to attend college?" style="margin-bottom:0px" prop="is_first_male">
                
                <el-select v-model="form.is_first_male" value-key="id">
                  <el-option label="No" value="No"></el-option>
                  <el-option label="Yes" value="Yes"></el-option>
                </el-select>
              </el-form-item><br>
              <span style="font-size: 12px; color: #818181;">This refers to all the male students who are the first in their immediate family (parents and siblings)  </span>
              
            </el-col>
          </el-row>
          </div><br><br>
      </el-form>
    </div>
</template>

<script>
import { Strand } from '@/api_services/strand'
import SearchCourseComponent from '@/components/common/SearchCourseComponent.vue'


export default {
  components: { SearchCourseComponent },
  name: 'Step3',
  props: ['form','step', 'check', 'save_counter', 'save_valid', 'refresh'],
  data() {
    const checkGPA = (rule, value, callback) =>{
      
      const numericValue = parseFloat(value);

      if (!isNaN(numericValue) && numericValue >= 60 && numericValue <= 100) {
        callback()
      } else {
        callback(new Error('Input not allowed. Accepted inputs: 60-100'))
      }

    };
    const validateCourseStatus = (rule, value, callback) =>{

      if (!value) {
        callback(new Error('Degree Program is required'));
        return;
      }

      if (!value.status && this.form.revert_ctr == null) {
        callback(new Error("Closed. Please select open Degree Programs"));
        return;
      }

      callback();
    };
    const checkStrandSelect  = (rule, value, callback) =>{
      console.log(value)
      if(!value && !this.is_other_strand) {
        callback(new Error('SHS Strand required'))
      } else {
        callback()
      }
    };
    const checkStrandCode  = (rule, value, callback) =>{
      console.log(value)
      console.log(this.is_other_strand)

      if(!value && this.is_other_strand) {
        callback(new Error('SHS Strand Code required'))
      } else {
        callback();
      }
    };
    const checkStrandDesc  = (rule, value, callback) =>{
      console.log(value)
      console.log(this.is_other_strand)
      if(!value && this.is_other_strand) {
        callback(new Error('SHS Strand Description required'))
      } else {
        callback();
      }
    };
    return {
      select_strand_key: 'default_key',
      is_other_strand: false,
      origin_strand: null,
      valid_attachments: false,
      social_categories: [],
      old_attachments: [],
      attachment_update: 0,
      strands: [],
      strand_id: null,
      is_first_female: null,
      is_first_male: null,
      rules: {
        course: [
          { trigger: 'blur' , validator: validateCourseStatus},
        ],
        campus: [
          { required: true, message: 'Campus is required', trigger: 'blur' },
        ],
        hs_type: [
          { required: true, message: 'Education attainment is required', trigger: 'blur' },
        ],
        hs_type_gpa: [
          { validator: checkGPA, trigger: 'blur' },
        ],
        grade_9_gpa: [
          { validator: checkGPA, trigger: 'blur' },
        ],
        grade_10_gpa: [
          { validator: checkGPA, trigger: 'blur' },
        ],
        grade_11_gpa: [
          { validator: checkGPA, trigger: 'blur' },
        ],
        pwd_handicap: [
          { required: false, message: 'PWD handicap is required', trigger: 'blur' },
        ],
        strand: [
          { validator: checkStrandSelect, trigger: 'blur' },
        ],
        other_strand_code: [
          { validator: checkStrandCode, trigger: 'blur' },
        ],
        other_strand_desc: [
          { validator: checkStrandDesc, trigger: 'blur' },
        ],
        is_first_female: [
          { required: true, message: 'Required field', trigger: 'blur' },
        ],
        is_first_male: [
          { required: true, message: 'Required field', trigger: 'blur' },
        ],
      },
      pwd_attachment: null,
      attachments: [],
      attachment_types: []
    }
  },
  watch: {
    'form.strand': function() {
      this.form.strand_id = this.form.strand ? this.form.strand.id : null;
      this.getStrands()
      this.select_strand_key = this.generateRandomKey()
      this.is_other_strand = !this.form.strand_id;
    },
    attachment_update: function() {
      // this.$emit('update:refresh', this.refresh + 1)
    },
    check: function() {
      this.checkForm()
    },
    attachments: {
        deep: true,
        handler() {
        }
    },
    save_counter: function() {
      this.checkForSave()
    }
  },
  created: function() {
    this.$emit('update:refresh', this.refresh+1)
    
    if (!this.form.social_categories) {
      this.form.social_categories = [false, false, false, false, false];
    }
    if (!this.form.social_categories.length) {
      this.social_categories = [false, false, false, false, false]
      this.form.social_categories = this.social_categories;
    } else {
      this.social_categories = this.form.social_categories
    }
    
    this.handleSocialCategoriesChange(this.social_categories[4], 4)
    this.getStrands();
    console.log(this.step)
  },
  methods: {
    truncateText(text, limit) {
      if (text.length > limit) {
        return text.substring(0, limit) + '...';
      }
      return text;
    },
    handleCategoryChange() {
      if (this.form.hs_type == 1) {
        const sum = (this.form.grade_9_gpa * 1) + (this.form.grade_10_gpa * 1) + (this.form.grade_11_gpa * 1);

        this.form.hs_type_gpa = (sum / 3).toFixed(3);
      }
    },
    handleSocialCategoriesChange(value, index) {
      if (!this.form.social_categories) {
        this.form.social_categories = [false, false, false, false, false]
      }
      this.form.social_categories[index] = value
      this.rules.pwd_handicap.required = false
      if (index == 4) {
        if (value) {
          this.rules.pwd_handicap[0].required = true
        }
      }
    },
    getAttainmentLabel(id) {
      let label = ""

      this.$store.state.hs_types.forEach(function(hs_type){
          if (hs_type.id === id) label = hs_type.value+""
      })
      return label
    },
    getFormType(id) {
      let form = null
      this.$store.state.form_types.forEach(function(form_type){
        if (form_type.id == id) form = form_type
      })
      return form
    },
    setSocialCategories() {
      this.form.social_categories = this.social_categories
    },
    setStrands(strands){
      this.strands = strands;

      if (this.form.strand){
        this.form.strand_id = this.form.strand.id;
      }
    },
    handleChangeStrand() {
      console.log(this.is_other_strand)
      if(this.is_other_strand) {
        this.origin_strand = this.form.strand
        this.form.strand = null
      } else {
        this.form.strand = this.origin_strand
        this.origin_strand = null
      }
    //  TODO: J1 strand exist error must remain in current state
    },
    getStrands(){
      // if (this.$store.state.strands.length) {
      //   this.setStrands(this.$store.state.strands);
      // } else {
        Strand.get({
          params: { page: 1, per_page: 999 },
        }).then(result => {
          if (result) {
            this.$store.state.strands = result.data.data;
            this.setStrands(result.data.data);
          } 
        }).catch( error => {
          console.error(error)
        })
      // }
    },
    async checkForSave() {
       this.$emit('update:save_valid', false)
      this.setSocialCategories()
      const valid = await this.$refs.form.validate();
      if (!valid) return
      this.$emit('update:save_valid', true)
    },
    async checkForm() {
      this.setSocialCategories()
      const valid = await this.$refs.form.validate();
      if (!valid) return;
      this.$emit('update:step', 3)
    },
    generateRandomKey() {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < 10; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
  }
}
</script>
