import api from './api'

const API = '/api/settings'

export const Setting = {
  get: function (params) {
    return api.get(API, params)
  },
  getOpenAndClose: function(params) {
    return api.get(API + '/open_and_close', params)
  },
  create: function(data) {
    return api.create(API, data);
  },
  update: function (id, params) {
    return api.update(API + '/' + id, params)
  },
  delete: function (id, params) {
    return api.delete(API + '/' + id, params)
  },
  getCloseSettings: function(params) {
    return api.get(API + '/close_settings', params)
  },
  getOthers: function(params) {
    return api.get(API + '/other_settings', params)
  },
  updateSettings: function (params) {
    return api.create(API + '/update_settings', params)
  },
}
