<template>
    <div class="Proctors">
        <el-form
        :model="form"
        ref="form">

        <el-row :gutter="10">
          <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
              <el-divider content-position="left"><span style="color: #00ab00"><b>Proctor Information</b></span></el-divider>
              <ul style="list-style-type: none;padding-left: 5px;">
                <li style="padding: 2px;">Proctor: <b>{{ exam_roster.proctor.name }}</b></li>
                <li style="padding: 2px;">Email: <b>{{ exam_roster.proctor.email }}</b></li>
                <li style="padding: 2px;">Number of applicants: <b>{{ rosterReport.length }}</b></li>
                <li style="padding: 2px;">Zoom link: <b>{{ exam_roster.zoom_link }}</b></li>
                
                <li>
                  <el-input placeholder="Input Zoom link here" type="text" v-model="form.zoom_link" style="width: 60%" size="small">
                    <el-button class="save-btn" type="success" slot="append" @click="updateLink" icon="el-icon-check">Save Zoom link</el-button>
                  </el-input>
                </li>
                
              </ul>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <el-divider content-position="left"><span style="color: #00ab00"><b>Exam Schedule Details</b></span></el-divider>
            <ul style="list-style-type: none;padding-left: 5px;">
              <li style="padding: 2px;">Date: <b>{{ dayy + " (" + day + ") " }}</b></li>
              <li style="padding: 2px;">start: <b>{{ start }}</b></li>
              <li style="padding: 2px;">end: <b>{{ end }}</b></li>
              <li>
                <el-button
                style="width: 30%; margin-top: 8px;"
                  type="success"
                  @click="sendEmailBlast"
                  size="small"
                  icon="el-icon-s-promotion">Send Email Blast
                </el-button>
                <el-button
                style="width: 30%; margin-top: 8px;"
                  type="info"
                  @click="generateRosterFile"
                  size="small"
                  :loading="load_pdf"
                  icon="el-icon-printer">Generate PDF File
                </el-button>
                <el-link class="newtabBtn" style="
                  width: 30%;
                  border: solid 1px #909399; 
                  padding-top: 8px;
                  padding-bottom: 8px;
                  margin-left: 8px;
                  background-color: #909399;
                  color: #ffffff;
                  border-radius: 3px;
                  font-size: 12px;
                  " :href="exam_roster.full_path" v-if="exam_roster.url" target="_blank"><i class="el-icon-document"></i> View PDF</el-link>
                
                
                <!-- <el-link  type="primary" :href="exam_roster.full_path" v-if="exam_roster.url" target="_blank">View Attachment</el-link> -->
              </li>
            </ul>
          </el-col>
          
        </el-row>

        <el-card style="margin-top: 5px; margin-bottom: 50px">
          <div style="margin: auto; text-align: right;">
            <el-button size="small" type="success" icon="el-icon-refresh" @click="refresh">Refresh</el-button>
          </div>
          <el-table 
          ref="multipleTable"
          :data="rosterReport.filter(data => !search || data.datails.toLowerCase().includes(search.toLowerCase()))"
          v-loading="loading"
          size="small" max-height="330">
            <el-table-column label="No." prop="index" width="50"> </el-table-column>
            <el-table-column prop="control_no" label="EXAMINEE CONTROL NUMBER (ECN)"></el-table-column>
            <el-table-column  label="NAME (LAST NAME, FIRST NAME)">
              <template slot-scope="scope" >
                {{ (scope.row.lastname + ", " + scope.row.firstname + " " + scope.row.middlename  ).toUpperCase() }} {{ !scope.row.extension ? " " : scope.row.extension }} 
              </template>
              
            </el-table-column>
            <el-table-column prop="email" label="EMAIL"></el-table-column>
            <el-table-column label="BIRTHDAY">
              <template slot-scope="scope" >
                {{ scope.row.birth_date }}  
              </template>
            </el-table-column>
            <el-table-column label="Send Email Notif" width="130" align="center">
                <el-button
                  type="success"
                  size="small"
                  icon="el-icon-s-promotion">
                </el-button>
            </el-table-column>
            <el-table-column label="Email Sent Status" width="130" align="center">
                <i class="el-icon-minus"></i>
            </el-table-column>
            <el-table-column align="left" width="220">
              <template slot="header" slot-scope="scope">
                <span hidden>{{ scope.id }}</span>
                <el-input
                  v-model="search"
                  size="mini"
                  placeholder="Search ECN / name / email"/>
              </template>
              <template slot-scope="scope">
                <el-button
                  style="width: 35%;"
                  size="mini"
                  type="info"
                  icon="el-icon-view"
                  @click="handleView(scope.row)">View
                </el-button>
                <el-button v-if="scope.row.showReshedBtn"
                  style="width: 45%;"
                  size="mini"
                  type="primary"
                  icon="el-icon-date"
                  @click="reschedule(scope.row)">Resched
                </el-button>
              </template>
            </el-table-column>
            
          </el-table>
        </el-card>

      </el-form>
      <el-dialog title="Set Exam Schedule" width="50%" :visible.sync="showExamDetails" v-if="showExamDetails" :destroy-on-close.sync="destroyOnClose" append-to-body>
        <applicant-exam-details-component @button-done="done" :applicant.sync="applicant" ></applicant-exam-details-component>
      </el-dialog>
      
    </div>
  </template>
  
  <style>
  .el-input-group__append {
    background-color: #67c23a!important;
    color: #ffffff!important;
  }
  .el-dialog__body {
    padding: 5px 20px!important;
  }
  </style>

  <script>
  
  import { User } from "@/api_services/user"; 
  import { Schedule } from "@/api_services/schedule";
  import { ExamRoster } from "@/api_services/examroster";
  import { ExamRosterReport } from "@/api_services/examrosterreport";
  import { Applicant } from "@/api_services/applicant";
  import ApplicantExamDetailsComponent from '@/components/applicants/ApplicantExamDetailsComponent.vue';
  import * as moment from 'moment';

  export default {
    name: "ViewRosterComponent",
    props: ["selected"],
    components: {ApplicantExamDetailsComponent},
  
    data() {
      return {
        exam_roster: [],
        rosterReport: [],
        loading: false,
        form: {
          zoom_link: '',
          zoom_meeting_id: '',
          zoom_passcode: ''
        },
        form_pdf: {
          rosterid: ''
        },
        schedule: null,
        day: "",
        dayy: "",
        start: "",
        end: "",
        search: '',
        showExamDetails: false,
        applicant: null,
        destroyOnClose: true,
        load_pdf: false
      };
    },
    created() {
      this.exam_roster = this.selected,
      this.day = moment(this.exam_roster.schedule.date).format('ddd');
      this.start = this.exam_roster.schedule.time_start.split(":")[0]+":"+this.exam_roster.schedule.time_start.split(":")[1];
      this.end = this.exam_roster.schedule.time_end.split(":")[0]+':'+this.exam_roster.schedule.time_end.split(":")[1];
      this.dayy = this.selected_date_time = moment(this.exam_roster.schedule.date).format('MMMM D, YYYY');
      this.loading = true;
      this.getExamRosterReport();
      console.log(this.exam_roster)
    },
    methods: {
      updateLink(){
        this.form.proctor_id = this.exam_roster.proctor.id;
        this.form.schedule_id = this.exam_roster.schedule.id;
        // console.log(this.form);
        ExamRoster.update(this.exam_roster.id, this.form)
        .then(response => {
          // console.log(response.data);
          this.exam_roster = response.data.data;
          // this.$emit('update', { exam_roster: this.exam_roster})
          this.$emit('update')
          this.$message({
            message: 'Zoom Link Saved',
            type: 'success'
          });
        }).catch((error) => {
          if (error.response.data.message) {
            this.$message({
              message: error.response.data.message,
              type: 'error',
              duration: 3000
            });
          }
        });
      },
      getExamRosterReport(){
        // this.loading = true;
        ExamRosterReport.get({
          params: { examroster_id: this.exam_roster.id, key: '1'},
        }).then((result) => {
          this.rosterReport = result.data.data;
          // console.log(this.rosterReport)
          this.rosterReport.forEach((roster, index) => {
            roster.index = index + 1
            roster.birth_date = moment(roster.birth_date).format('MMMM D, YYYY')
            roster.datails = roster.control_no + " " + roster.firstname + " " + roster.lastname + " " + roster.email + " " + roster.birth_date
            
            roster.showReshedBtn = roster.applicant_exam_detail.schedule_id == this.exam_roster.schedule.id ? true : false
            // roster.applicant_exam_detail.schedule_id
          });

          // console.log(this.rosterReport);
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
        });
      },
      exportRoster(Schedule){
        Applicant.exportCsvList({
          sched_id: Schedule.id
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'applicants_'+ Schedule.date +'('+Schedule.time_start+'-'+Schedule.time_end+').csv');
          document.body.appendChild(link);
          link.click();
        });
      },
      generateRosterFile(){
        this.load_pdf = true
        this.form_pdf.rosterid = this.exam_roster.id
        ExamRoster.generatepdf(this.form_pdf)
        .then((result) => {
          // console.log(result.data.data);
          let data = result.data
          this.load_pdf = false
          this.exam_roster.url = data.link
          this.exam_roster.full_path = data.full_path
          this.$message({
            message: 'PDF file successfully generated: ' + data.file_name,
            type: 'success'
          });
          this.$emit('update')
        }).catch((error) => {
          this.load_pdf = false;
        });
      },
      handleView(report){
        // console.log(report)
        window.focus();
        let routeData = this.$router.resolve({name: 'SelectedApplicant', params: { id: report.id }});
        window.open(routeData.href, '_blank')
        
      },
      reschedule(applicant){
        this.showExamDetails = true;
        // console.log(applicant)
        this.applicant = Object.assign({}, applicant)
      },
      done(){
        this.showExamDetails = false
        this.getExamRosterReport()
      },
      sendEmailBlast(){
        this.$confirm('Are you sure you want to send email blast to this exam roster?')
      },
      refresh(){
        this.loading = true;
        this.getExamRosterReport()
      }
      
    },
  };
  </script>
  