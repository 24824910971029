<template>
  <div class="Configure" style="padding: 20px;">
    <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" style="color: #f3f3f3;">
          .
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-card>                         
              <el-form v-loading="loading" ref="form" class="applicant-step-forms" :model="form" @submit.native.prevent="saveSettings()">
                <el-row :gutter="20">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-divider content-position="left"><span style="color: #00ab00"><b>Open / Close Settings</b></span></el-divider>
                    <el-form-item label="Open:">
                      <el-date-picker v-model="form.open_date" type="date" placeholder="Open date" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="Close:">
                      <el-date-picker v-model="form.close_date" type="date" placeholder="Close date" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="Close Unsubmitted (Non-reverted):">
                      <el-switch v-model="form.close_unsubmitted">
                      </el-switch>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="Close all Reverted:">
                      <el-switch v-model="form.close_reverted">
                      </el-switch>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-divider content-position="left"><span style="color: #00ab00"><b>Labels</b></span></el-divider>
                    <el-form-item label="VSU-CAT year:">
                      <el-date-picker v-model="form.cat_year" type="year" placeholder="VSU-CAT year" style="width: 100%;"></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="Head of Admissions:">
                      <el-input v-model="form.admissions_head" type="text" placeholder="Head of Admissions" style="width: 100%;"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="Email 1:">
                      <el-input v-model="form.email1" type="email" placeholder="Email 1" style="width: 100%;"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="Email 2:">
                      <el-input v-model="form.email2" type="email" placeholder="Email 2" style="width: 100%;"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-divider content-position="left"><span style="color: #00ab00"><b>Ranking Settings</b></span></el-divider>
                    <el-form-item label="MAIN campus Cut-off Rating:">
                      <el-input v-model="form.main" type="number" placeholder="MAIN campus Cut-off" style="width: 100%;"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="TOLOSA campus Cut-off Rating:">
                      <el-input v-model="form.tolosa" type="number" placeholder="TOLOSA campus Cut-off" style="width: 100%;"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="ALANGALANG campus Cut-off Rating:">
                      <el-input v-model="form.alangalang" type="number" placeholder="ALANGALANG campus Cut-off" style="width: 100%;"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="ISABEL campus Cut-off Rating:">
                      <el-input v-model="form.isabel" type="number" placeholder="ISABEL campus Cut-off" style="width: 100%;"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="VILLABA campus Cut-off Rating:">
                      <el-input v-model="form.villaba" type="number" placeholder="VILLABA campus Cut-off" style="width: 100%;"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="Social category % per Cut-off (as per BOR approved)">
                      <el-input v-model="form.sc_percentage" type="number" placeholder="Social category % per Cut-off (as per BOR approved)" style="width: 100%;"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <div style="text-align:center;margin-top: 20px;">
                  <el-button type="success" @click="saveSettings()" icon="el-icon-check" :loading="loading"> Save</el-button>
                </div>
              </el-form>
            </el-card>
            </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">

        </el-col>
    </el-row>

    <el-dialog title="Add applicant" width="75%" :visible.sync="showDialog">
      <edit-config-component ></edit-config-component>
    </el-dialog>
  </div>

</template>

<style>
  .right {
    text-align: right;
  }

  .add-btn {
    margin-bottom: 1rem;
  }
</style>

<script>

import { Setting } from '@/api_services/setting'
import EditConfigComponent from './EditConfigComponent.vue';

export default {
  components: {
    EditConfigComponent
  },
  name: 'Configure',
  data() {
    return {
      showDialog: false,
      settings: [],
      loading: false,
      form: {
        open_date: null,
        close_date: null,
        close_unsubmitted: false,
        close_reverted: false,
        cat_year: null,
        admissions_head: null,
        email1: null,
        email2: null,
        main: null,
        tolosa: null,
        alangalang: null,
        isabel: null,
        villaba: null,
        sc_percentage: null,
      }
    }
  },
  created() {
    this.getSettings()
  },
  methods: {
    addApplicantForm() {
      this.showDialog = true;
    },
    getSettings() {
      this.loading = true

      Setting.getOpenAndClose({
      }).then((result) => {
        this.form.close_date = result.data.close
        this.form.open_date = result.data.open
        console.log(result.data);
      }).catch((error) => {

      });

      Setting.getCloseSettings({
      }).then((result) => {
        this.form.close_unsubmitted = result.data.close_unsubmitted
        this.form.close_reverted = result.data.close_reverted
        this.loading = false
      }).catch((error) => {

      });

      Setting.getOthers({
      }).then((result) => {
        this.form.cat_year = result.data.cat_year
        this.form.admissions_head = result.data.admissions_head
        this.form.email1 = result.data.email1
        this.form.email2 = result.data.email2
        this.form.main =  result.data.main
        this.form.tolosa =  result.data.tolosa
        this.form.alangalang =  result.data.alangalang
        this.form.isabel =  result.data.isabel
        this.form.villaba = result.data.villaba
        this.form.sc_percentage = result.data.sc_percentage

        this.loading = false
      }).catch((error) => {

      });
    },
    saveSettings(){
      this.$confirm('Are you sure you want to update the VSU-CAT Settings?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.loading = true
          Setting.updateSettings(this.form)
            .then((response) => {
              // this.$emit("saved", response.data.data);
              this.$message({
                type: 'success',
                message: 'Configuration Saved!'
              });
              this.loading = false
            }).catch((error) => {
              this.$message({
                message: error.data,
                type: "error",
                duration: 3000,
              });
            });
          
        }).catch(() => {
               
        });
    }
  }
}
</script>
