<template>
    <div> 
        <el-row :gutter="20" style="padding:10px">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
               <el-row :gutter="20" style="">
                  <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" style="margin-bottom: 10px">
                    <el-alert
                      title="Scheduled Applicants"
                      type="success"
                      class="bg-orange"
                      effect="dark"
                      :closable="false">
                      <div style="font-size:50px;">{{ result.ready_for_exam}}</div>
                      <i class="el-icon-date dashboard-icon"></i>
                    </el-alert>
                    
                  </el-col>

                  <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" style="margin-bottom: 10px">
                    <el-alert
                      title="Pending Reviews"
                      type="warning"
                      effect="dark"
                      :closable="false">
                      <div style="font-size:50px;">{{ result.pending}}</div>
                      <i class="el-icon-edit-outline dashboard-icon"></i>
                    </el-alert>
                    
                  </el-col>

                  <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" style="margin-bottom: 10px">
                    <el-alert
                      title="Passed"
                      type="success"
                      effect="dark"
                      :closable="false">
                      <div style="font-size:50px;">{{ result.passed}}</div>
                      <i class="el-icon-check dashboard-icon"></i>
                    </el-alert>
                  </el-col>
              </el-row>
              <el-row :gutter="20" style="">
                <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" style="margin-bottom: 10px">
                  <el-alert
                      title="Total Successful Takers"
                      type="info"
                      class="bg-blue"
                      effect="dark"
                      :closable="false" style="margin-top: 10px">
                      <div style="font-size:50px;">{{ result.total_sucess_takers}}</div>
                      <i class="el-icon-user-solid dashboard-icon"></i>
                    </el-alert>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" style="margin-bottom: 10px">
                  <el-alert
                    title="Online Takers"
                    type="info"
                    effect="dark"
                    :closable="false" style="margin-top: 10px">
                    <div style="font-size:50px;">{{ result.online_takers}}</div>
                    <i class="el-icon-mouse dashboard-icon"></i>
                  </el-alert>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" style="margin-bottom: 10px">
                  <el-alert
                    title="Face-to-face Takers"
                    type="info"
                    effect="dark"
                    :closable="false" style="margin-top: 10px">
                    <div style="font-size:50px;">{{ result.ftof_takers}}</div>
                    <i class="el-icon-notebook-2 dashboard-icon"></i>
                  </el-alert>
                </el-col>
              </el-row>

          </el-col>

           <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>Upcoming Schedules</span>
              </div>
              <el-table
                :data="schedules"
                style="width: 100%"
                v-loading="loading">
                <el-table-column
                  prop="date"
                  label="Date"
                  width="180">
                </el-table-column>
                <el-table-column
                  prop="time_start"
                  label="Start Time"
                  width="180">
                </el-table-column>
                <el-table-column
                  prop="time_end"
                  label="End Time">
                </el-table-column>
                <el-table-column
                  prop="entries"
                  label="Entries">
                </el-table-column>
                <el-table-column
                  prop="slots"
                  label="Slots">
                </el-table-column>
              </el-table>
              <div style="margin: auto; text-align: center;">
                <el-pagination
                  class="margin-top-20"
                  background
                  layout="prev, pager, next"
                  :total="meta.total"
                  :page-size="15"
                  @current-change="pageChanged"
                  :current-page="meta.page"
                >
                </el-pagination>
              </div>

            </el-card>
          </el-col>

        </el-row>

        <el-row :gutter="20" style="padding:20px">
          
        </el-row>
    </div>
</template>

<script>

import { Schedule } from "@/api_services/schedule";
import { Applicant } from "@/api_services/applicant";
import * as moment from 'moment';


export default {
  name: 'Dashboard',
  data() {
    return {
        schedules: [],
        result: {passed: 0, pending: 0, ready_for_exam: 0, total_sucess_takers: 0, online_takers: 0, ftof_takers: 0},
        meta: {
          page: 1,
          pages: 1,
          total: 211,
          per_page: 15,
        },
        loading: false
    }
  },
  created: function() {
    this.getSchedules()
    this.getAnalytics()
  },
  methods: {
    getSchedules() {
      let date = moment().format('YYYY-MM-DD')
      Schedule.get({
        params: { page: this.meta.page, from: date,},
      }).then((result) => {
          this.schedules = result.data.data
          this.schedules.forEach(sched => {
            let start = sched.time_start.split(":")[0]+":"+sched.time_start.split(":")[1];
            let end = sched.time_end.split(":")[0]+':'+sched.time_end.split(":")[1];
            sched.time_start = this.formatTime(sched.time_start)
            sched.time_end = this.formatTime(sched.time_end)
            sched.date = moment(sched.date).format('MMMM D, YYYY')
          });
          this.meta = {
            page: result.data.meta.current_page,
            total: result.data.meta.total,
            per_page: result.data.meta.per_page,
            pages: result.data.meta.last_page,
          };
          this.loading = false
      }).catch((error) => {
          console.log(error)
      });
    },
    pageChanged(val) {
      this.loading = true
      this.meta.page = val;
      this.getSchedules();
    },
    formatTime(time) {
      const [hours, minutes] = time.split(':');
      let hourNum = parseInt(hours, 10);
      const period = hourNum >= 12 ? 'PM' : 'AM';
      hourNum = hourNum % 12 || 12;
      const formattedTime = `${hourNum}:${minutes} ${period}`;

      return formattedTime;
    },
    getAnalytics() {
      Applicant.analytics({}).then((result) => {
          this.result = result.data
      }).catch((error) => {
          console.log(error)
      });
    }
  }
}
</script>

<style scoped>
  .dashboard-icon {
        position: absolute;
    top: 15px;
    right: 15px;
    font-size: 80px;
    opacity: .6;
  }
</style>