<template>
  <div class="Email">
    <el-form
    :model="emailForm"
      ref="emailForm"
      label-width="100px"
      :rules="rules"
    >
      <el-form-item label="New Email" prop="email">
        <el-input type="email" v-model="emailForm.email" style="width: 100%;"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" style="width: 100%;" @click="update">Save</el-button>
        <!-- <el-button @click="resetForm">Reset</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { Applicant } from '@/api_services/applicant'
export default {
  name: "UpdateEmailComponent",
  props: ["selected"],

  data() {
    return {
      emailForm: [],
      rules: {
        email: [
          { required: true, message: 'Email address is required', trigger: 'blur' },
          { type: 'email', message: 'Invalid email address', trigger: ['blur', 'change'] }
        ],
      }
    };
  },
  created() {
    
    this.emailForm = this.selected;
    console.log(this.emailForm);
  },
  methods: {
    async update() {
      this.$confirm('Are you sure you want to update the email of <strong>' + this.emailForm.firstname + ' ' + this.emailForm.lastname +'</strong>. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        dangerouslyUseHTMLString: true,
      }).then(() => {
        Applicant.updateEmail({email: this.emailForm.email, id:this.emailForm.id})
        .then((response) => {
          // console.log(this.email);
          this.$emit("saved", response.data.data);
          this.$message({
            type: 'success',
            message: 'Email updated'
          });
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.$message({
              message: error.response.data.message,
              type: "error",
              duration: 3000,
            });
          }
        });
      }).catch(() => {

      });
    }
  },
};
</script>
