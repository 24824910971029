<template>
  <div id="courses">
    <el-form :inline="true" :model="searchForm" class="demo-form-inline">
      <el-form-item label="Search Degree Programs">
        <el-input
          v-model="searchForm.name"
          placeholder="Code or Description"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="onSearch"
          size="small"
          :loading="loading"
          icon="el-icon-search"
          >Search</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-tag>Total: {{ meta.total }}</el-tag>
      </el-form-item>
    </el-form>

    <el-button type="primary" size="medium" @click="addForm()" v-if="$store.state.savedUser.role === 'admin'">ADD Degree Program</el-button>

    <el-table :data="courses" stripe style="width: 100%">
      <el-table-column prop="id" label="ID" width="70"></el-table-column>
      <el-table-column prop="code" label="Code"></el-table-column>
      <el-table-column prop="description" label="Description" width="320"></el-table-column>
      <el-table-column prop="major" label="Major"></el-table-column>
      <el-table-column prop="campuses" label="Campuses"></el-table-column>
      <el-table-column label="Status">
        <template slot-scope="scope" >
          <b v-if="scope.row.status == true" style="color:#67C23A">open</b>
          <b v-if="scope.row.status == false" style="color:#F56C6C">close</b>
        </template>
      </el-table-column>
      <el-table-column label="Number of applicants" width="100"></el-table-column>
      <el-table-column prop="quota" label="Quota (MAIN campus only)" width="200"></el-table-column>
      <el-table-column v-if="$store.state.savedUser.role === 'admin'"
        fixed="right"
        label="Operations"
        width="160">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small">Edit</el-button>
          <el-button @click="deleteCourse(scope.row)" type="text" size="small">Delete</el-button>
          <el-button  type="text" size="small">Print</el-button>
          
        </template>
      </el-table-column>
    </el-table>


    <el-pagination
      class="margin-top-20"
      background
      layout="prev, pager, next"
      :page-size="15"
      :total="meta.total"
      @current-change="pageChanged"
      :current-page="meta.page"
    >
    </el-pagination>

    <el-dialog title="Degree Program" width="75%" :visible.sync="showDialog" v-if="showDialog" :destroy-on-close.sync="destroyOnClose">
      <course-component :selected="selected" @saved="courseSaved"></course-component>
    </el-dialog>
  </div>
</template>

<script>
import { Course } from "@/api_services/course";
import CourseComponent from './CourseComponent.vue';

export default {
  components: {
    CourseComponent
  },
  name: "Courses",
  data() {
    return {
      destroyOnClose: true,
      loading: false,
      searchForm: {},
      courses: [],
      meta: {
        page: 1,
        pages: 1,
        total: 211,
        per_page: 10,
      },
      selected: null,
      showDialog: false
    }
  },
  created() {
    this.onSearch();
  },
  methods: {
    handleClick(course) {
      this.selected = Object.assign({}, course);
      this.showDialog = true;
    },
    deleteCourse(course) {
      if (confirm('Are you sure you want to delete this Degree program?')) {

        Course.delete(course.id, {}).then((response) => {
          this.getCourses();
        }).catch((error) => {
          if (error.response.data.message) {
            this.$message({
              message: error.response.data.message,
              type: "error",
              duration: 3000,
            });
          }
        });
      }
    },
    addForm() {
      this.showDialog = true;
      this.selected = null;
    },
    courseSaved() {
      this.showDialog = false;
      this.selected = null;
      this.getCourses();
    },
    onSearch() {
      this.meta.page = 1;
      this.loading = true;
      this.getCourses();
    },
    pageChanged(val) {
      this.meta.page = val;
      this.getCourses();
    },
    getCourses() {
      Course.get({
        params: { page: this.meta.page, term: this.searchForm.name },
      })
        .then((result) => {
          this.courses = result.data.data;
          this.meta = {
            page: result.data.meta.current_page,
            total: result.data.meta.total,
            per_page: result.data.meta.per_page,
            pages: result.data.meta.last_page,
          };
          this.loading = false;
          // console.log(this.courses);
        })
        .catch((error) => {
          this.loading = false;
          this.applicants = [];
        });
    }
  }
};
</script>
