<template>
    <div style="text-align:center" >
        <navbar-component></navbar-component>
        <h1 style="text-align:center">Welcome To VSU Admission App</h1>
        <el-carousel :interval="4000" type="card" >
            <el-carousel-item v-for="image in images" :key="image">
              <img :src="require('@/assets/images/'+image)" style="width:100%">
            </el-carousel-item>
        </el-carousel>
        
        
            <el-button class="lg-btn" style="text-align:center"  type="primary" round @click="centerDialogVisible = true">Get Started <i class="el-icon-arrow-right el-icon-right"></i></el-button>
        

        <el-dialog
          :visible.sync="centerDialogVisible"
          width="30%"
          center
          class="responsive-dialog">
          <div style="text-align: center;">
            <h1 style="color: #008300;">Welcome Applicant!</h1><br><br>
            <span style="font-weight: bold; color: #2c3e50;">Have you decided on what Degree program to take?</span><br>
          </div><br>
          
          <div>
            <el-button style="word-wrap: break-word; white-space: normal; width: 100%; border-radius: 35px;" class="lg-btn" @click="splashPrivacyNotice()">I'm sure what to take</el-button><br>
            <el-button style="word-wrap: break-word; white-space: normal; width: 100%; margin-top: 20px; border-radius: 35px;" class="lg-btn" type="primary" @click="gotoSite()" >Know more about the Degree programs</el-button>
          </div>

        </el-dialog>

    <el-dialog
      class="application-dialog"
      title=""
      :visible.sync="centerDialogVisible_notice"
      width="40%"
      center>
      <terms-and-condition-component></terms-and-condition-component>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible_notice = false">I do not agree</el-button>
        <el-link href="/start" class="vsu-link" >
        <el-button style=";margin-left: 20px" type="primary"  >I Agree</el-button>
        </el-link>
      </span>
    </el-dialog>

    </div>
</template>

<script>
import NavbarComponent from '@/components/common/NavbarComponent.vue'
import TermsAndConditionComponent from '@/components/common/TermsAndConditionComponent.vue';


export default {
  name: 'Landing',
  components: { NavbarComponent, TermsAndConditionComponent },
  data() {
    return {
      centerDialogVisible: false,
      centerDialogVisible_notice: false,
      images: ['CAT 2024 img 3.png', 'CAT 2024 img 2.jpg', 'CAT 2024 img 1.webp']
    }
  },
  methods: {
    gotoSite() {
      window.open("https://www.vsu.edu.ph/admission/college");
    },
    splashPrivacyNotice(){
      this.centerDialogVisible_notice = true
    },
  },
}
</script>

<style>
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    /* background-color: #99a9bf; */
  }

  .el-carousel__item:nth-child(2n+1) {
    /* background-color: #d3dce6; */
  }
</style>