<template>
    <div style="padding: 20px" v-loading.fullscreen.lock="loading">
        <div v-if="!loading"></div>
            

        <div v-if="Schedule">
            <!-- <applicant-component :applicant.sync="applicant"></applicant-component> -->
            <div>            
            <el-row :gutter="20">
                <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                    <el-divider content-position="left">Time and Date</el-divider>
                    <ul style="list-style-type: none;padding-left: 5px;">
                    <li style="padding: 5px;">Date : {{ getDate(Schedule.date) }}</li>
                    <li style="padding: 5px;">Start : {{ Schedule.time_start}}</li>
                    <li style="padding: 5px;">End : {{ Schedule.time_end}}</li>
                    </ul>
                </el-col>

                <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                    <el-divider content-position="left">Slots</el-divider>
                    <ul style="list-style-type: none;padding-left: 5px;">
                    <li style="padding: 5px;">Slots : {{ Schedule.slots}}</li>
                    <li style="padding: 5px;">Accupied Slot/s : {{ Schedule.entries}}</li>
                    <li style="padding: 5px;">Available Slot/s : {{ Schedule.slots - Schedule.entries}}</li>
                    </ul>
                </el-col>

                <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                    <el-divider content-position="left">Export</el-divider>
                    <ul style="list-style-type: none;padding-left: 5px;">
                        <el-button type="primary" size="small" @click="exportApplicants(Schedule)">Export Applicant</el-button>
                    </ul>
                    <el-divider content-position="left">Results</el-divider>
                    <ul style="list-style-type: none;padding-left: 5px;">
                      <li style="padding: 5px;">Status : Unavailable</li>
                        <!-- <el-button type="primary" size="small" @click="importResults(Schedule)">Import</el-button> -->
                        <form>
                            <input type="file" class="form-control" name="import_scores">
                            <!-- <button class="btn btn-primary btn-block">Upload</button> -->
                            <el-button type="success" size="small" @click="generateResults(Schedule)" :loading="loading">Generate Results</el-button>
                        </form>

                        <div v-if="loadingGenerate" v-cloak>
                          <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
                          <span>Loading...</span>
                        </div>
                    </ul>
                </el-col>
            </el-row>

            <el-table :data="applicants" stripe style="width: 100%; font-size:small">
            <el-table-column prop="id" label="ID" width="70"></el-table-column>
            <el-table-column prop="lastname" label="Last Name" ></el-table-column>
            <el-table-column prop="firstname" label="First Name" ></el-table-column>
            <el-table-column prop="middlename" label="Middle Name" ></el-table-column>
            <el-table-column prop="extension" label="Ext. Name" ></el-table-column>
            <el-table-column prop="contact_no" label="Contact No." ></el-table-column>
            <el-table-column prop="email" label="Email" ></el-table-column>
            <el-table-column  label="Moodle Username" ></el-table-column>
            <el-table-column  label="Moodle Password" ></el-table-column>
            <el-table-column  label="Result status" >Unavailable</el-table-column>
            <el-table-column  label="Attempts" >-</el-table-column>
            </el-table>

            <el-pagination
            class="margin-top-20"
            background
            layout="prev, pager, next"
            :total="meta.total"
            @current-change="pageChanged"
            :current-page="meta.page"
            >
            </el-pagination>

            </div>
        </div>
          <page-not-found :message="'Schedule not found'" v-else></page-not-found>
    </div>
</template>

<script>

import * as moment from 'moment';
import { Schedule } from '@/api_services/schedule'
import { Applicant } from "@/api_services/applicant";

export default {
  name: 'SelectedSchedulePage',
  data() {
    return {
       loading: false,
       loadingGenerate: false,
       Schedule: null,
       applicants: [],
      //  id: this.id,
      //  schedule_id: null,
       meta: {
        page: 1,
        pages: 1,
        total: 211,
        per_page: 10,
      }
    }
  },
  created: function() {
     this.getScheduled(this.$router.currentRoute.params.id)
     this.onSearch();
  },
  methods: {
    getDate(date) {
        return moment(date).format('MMMM D, YYYY')
    },
    getScheduled(id) {
      this.loading = true
      Schedule.show(id)
        .then(result => {
          this.Schedule = result.data.data
          this.loading = false
          // console.log(result.data.data.id)
        })
        .catch( error => {
          this.loading = false
          console.log(error)
        })
    },
    onSearch() {
      this.meta.page = 1;
      this.loading = true;
      this.getApplicants(this.$router.currentRoute.params.id);
    },
   getApplicants(id) {
      Applicant.getList({
        params: { page: this.meta.page, id: id},
        // params: { page: this.meta.page, status: 3 },
        
      })
        .then((result) => {
          console.log(id + 'test')
          this.applicants = result.data.data;
          this.meta = {
            page: result.data.meta.current_page,
            total: result.data.meta.total,
            per_page: result.data.meta.per_page,
            pages: result.data.meta.last_page,
          };
          this.loading = false;
          // console.log(result.data.data);
        })
        .catch((error) => {
          this.loading = false;
          this.applicants = [];
        });
    },
    pageChanged(val) {
      this.meta.page = val;
      this.getApplicants();
    },
    exportApplicants(Schedule) {

      Applicant.exportCsvList({
        sched_id: Schedule.id
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'applicants_'+ Schedule.date +'('+Schedule.time_start+'-'+Schedule.time_end+').csv');
        document.body.appendChild(link);
        link.click();
      });
    },
    generateResults(Schedule) {
      this.loading = true;
    },
  }
}
</script>