<template>
  <div style="padding: 20px" class="applicant-step-forms">
    <el-divider content-position="left">Attachments</el-divider>
    <el-row :gutter="20" v-for="attachmentType in attachmentTypes" :key="attachmentType.id">
      <el-col  style="margin-bottom: 10px;">
        <attachment-form-type-component :attachment_update.sync="attachment_update" :type="attachmentType" :applicant.sync="applicant"></attachment-form-type-component>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import AttachmentFormTypeComponent from '@/components/common/attachments/AttachmentFormTypeComponent.vue'

export default {
  name: 'Step3b',
  components: { AttachmentFormTypeComponent },
  props: ['applicant','step', 'check', 'save_counter', 'save_valid', 'refresh'],
  data() {
    return {
      attachments: [],
      attachmentTypes: null,
      attachment_update: 0
    }
  },
  watch: {
    attachment_update: function() {
      this.$emit('update:refresh', this.refresh + 1)
    },
    check: function() {
      this.checkForm()
    },
    applicant: {
      deep: true,
      handler() {
        // console.log(this.applicant)
        // this.buildAttachmentForms();
      }
    }
  },
  created: function () {
    this.buildAttachmentForms();
  },
  methods: {
    buildAttachmentForms() {
      this.attachmentTypes = [];
      const attachmentMap = this.$store.state.attachment_types_map.find(attachmentType => attachmentType.hs_type === this.applicant.hs_type);

      attachmentMap.form_types.forEach(type => {
        this.attachmentTypes.push(this.$store.state.form_types.find(f => f.id === type));
      });

      this.applicant.social_categories.forEach((cat, idx) => {
        if (cat === true) {
          const socialMapIndex = this.$store.state.social_categories_and_attachment_types[idx];

          this.attachmentTypes.push(this.$store.state.form_types.find(f => f.id === socialMapIndex));
        }
      });
    },

    async checkForSave() {
      // this.$emit('update:save_valid', false)
      // this.setSocialCategories()
      // const valid = await this.$refs.form.validate();
      // if (!this.checkOthers()) return
      // if (!valid) return
      // this.$emit('update:save_valid', true)
    },
    validateAttachments() {
      let valid = true;
      if (!this.applicant.attachments.length) {
        this.valid_attachments = false;
        return;
      }

      this.attachmentTypes.forEach(attachmentType => {
        const attachmentLen = this.applicant.attachments.filter(a => a.type === attachmentType.id).length;
        if (!attachmentLen) {
          valid = false;
        }
      });

      this.valid_attachments = valid
    },
    checkOthers() {
      this.validateForm()

      if (!this.valid_attachments) {
        this.$message({
          message: 'Please complete the attachments section',
          type: 'error',
          duration: 3000
        });

        return false;
      }

      return true
    },
    async checkForm() {
      this.validateAttachments();
// console.log("flag0")
      if (!this.valid_attachments) {
        this.$message({
          message: 'Please complete the attachments section',
          type: 'error',
          duration: 3000
        });

        return;
      }
      this.$emit('update:step', 4)
    }
  }
}

</script>