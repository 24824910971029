<template>
    <div class="Proctors">
      <el-form
      :model="form"
        ref="form"
        label-width="20px"
        v-if="!submitted"
      >
        <div style="margin-bottom: 5px">
            <span>Number of Proctors: {{ number_of_proctors }}</span><br>
            <span>Number of Applicants: {{ number_of_slots }}</span><br>
            <span>Number of takers per Proctor (estimate): {{ takers_per_proctor }}</span><br/><br/>
            
        </div>
        <small style="color:#9090a5">*Assign proctors by toggling the checkbox</small>
        
        <el-card style="margin-top: 5px">
          <el-table 
          ref="multipleTable"
          :data="tableData"
          v-loading.fullscreen.lock="fullscreenLoading"
          @selection-change="handleSelectionChange" size="small" max-height="320">
            <el-table-column type="selection" width="55" />
            <el-table-column prop="name" label="Proctor"></el-table-column>
            <el-table-column prop="email" label="email" width="300"></el-table-column>
          </el-table>
        </el-card>

        <div style="margin-top: 20px">
            <el-button :disabled="number_of_proctors == 0 || number_of_slots == 0" type="success" @click="update" style="width: 100%;">SAVE AND GENERATE ROSTER</el-button>            
        </div>
        
        </el-form>
    </div>
  </template>

  <script>
  

  import { User } from "@/api_services/user"; 
  import { ExamRoster } from "@/api_services/examroster";
  import { Schedule } from "@/api_services/schedule"; 
  

  export default {
    name: "EditProctorsComponent",
    props: ["selected_schedule"],

    data() {
      return {
        form: {
          schedule_id: "",
          slots_per_proctor: "",
          zoom_link: "",
          proctor_ids: "",
          assigned_proctors: ""
        },
        tableData: [],
        loading: false,
        takers_per_proctor: 0,
        multipleSelection: [],
        number_of_proctors: 0,
        number_of_slots: 0,
        final_selected_proctors: [],
        submitted: false,
        selected_schedule_tmp: this.selected_schedule,
        fullscreenLoading: false
      };
    },
    created() {
      // this.form = this.selected_schedule;
      // const ids = [];
      this.getUsers();
      
      // console.log("this" + this.selected_schedule_tmp.assigned_proctor_ids);
      this.number_of_slots = this.selected_schedule_tmp.entries;
      this.form.schedule_id = this.selected_schedule_tmp.id;
      
    },
    methods: {
      openFullScreen1() {
        this.fullscreenLoading = true;
        setTimeout(() => {
          this.fullscreenLoading = false;
        }, 12000);
      },
      update() {
        const proctor_ids = []
        let msg = 'Are you sure you want save assigned proctors and generate exam roster? ' 

        this.multipleSelection.forEach(p => {
          proctor_ids.push(p.id);
        });
        console.log(proctor_ids);
        this.form.proctor_ids = proctor_ids;
        console.log("proctors: " + this.number_of_proctors);
        console.log("takers: " + this.takers_per_proctor);

        if(this.selected_schedule_tmp.assigned_proctor_ids != null){
          msg = msg + "Previously generated proctors and exam roster for this schedule will be re-written. Override?"
        }

        this.$confirm(msg, {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          
          this.openFullScreen1();
          Schedule.storeProctorIds(this.form).then(response => {
            console.log(response.data);
            this.$emit("saved", response.data.data);
            this.$message({
              message: 'Proctors successfully saved! Please wait for the Exam Roster to generate',
              type: 'success'
            });
            
          }).catch((error) => {
            if (error.response.data.message) {
              this.$message({
                message: error.response.data.message,
                type: 'error',
                duration: 3000
              });
            }
          });

          this.generateRoster();

        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: 'canceled'
          // });
        });
      },
      generateRoster(){
        ExamRoster.createRoster(this.form).then(response => {
          console.log(response.data);
          this.$emit("saved", response.data.data);
          this.$message({
            message: 'Exam roster generated!',
            type: 'success'
          });
          this.$emit('button-done');
        }).catch((error) => {
          if (error.response.data.message) {
            this.$message({
              message: error.response.data.message,
              type: 'error',
              duration: 3000
            });
          }
        });
        console.log("roster test");
      },
      getUsers() {
        User.getProctors({
          params: { term: '' },
        }).then((result) => {
          this.tableData = result.data.data;
        }).catch((error) => {
          console.log(error);
        });
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
        this.number_of_proctors = this.multipleSelection.length;

        if(this.number_of_proctors > 0){
          this.takers_per_proctor = parseInt(this.selected_schedule_tmp.entries) / parseInt(this.number_of_proctors);
        }else{
          this.takers_per_proctor = 0
        }
        
      },
    },
  };
  </script>
  