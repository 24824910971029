import api from './api'

const API = '/api/rankings'

export const Ranking = {
    get: function (params) {
        return api.get(API, params)
    },
    create: function(data) {
        return api.create(API, data);
    },
    update: function (id, params) {
        return api.update(API + '/' + id, params)
    },
    delete: function (id, params) {
        return api.delete(API + '/' + id, params)
    },
    generateRanking: function(params) {
        return api.create(API + '/generate-ranking', params)
    },
    updateTag: function(params) {
        return api.create(API + '/update-tag', params)
    },
    exportCsvList: function(params) {
        return api.download(API + '/export-list', params)
    },
    saveTags: function(params) {
        return api.create(API + '/save-tags', params)
    },
}
