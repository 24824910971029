<template>
  <div style="padding: 20px;">
    <el-row :gutter="20">

      

        <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">

          <template>
            <el-tabs type="border-card" v-loading="loading">
              <el-tab-pane label="Import Moodle Results">
                <p style="font-size: small">* Only for importing moodle results</p>
                <el-card>
                  <el-form ref="form" :model="results">
                      <el-alert style="margin-bottom:10px" :closable="false" title="Before uploading please rename the column headers to:" type="warning" 
                                description="`control_no`, `cat_score`"/>
                      <el-alert style="margin-bottom:10px" :closable="false" title="Upload exam results (.csv, .xlsx) based on date and time of schedule respectively." type="warning" 
                                description=""/>
                      <el-alert style="margin-bottom:10px" :closable="false" title="Before Importing F2F results make sure to import first the applicant's data. Otherwise will result into error." type="error" 
                                description=""/>
                      <el-form-item>   

                        <el-upload
                        name="results_file"
                        action=""
                        :on-change="handleUploadChange"
                        :before-upload="handleBeforeUpload"
                        accept=".csv"
                        :file-list="fileList"
                        ref="upload"
                        :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">Choose file</el-button>
                        

                          <el-popconfirm
                            confirm-button-text="Continue"
                            cancel-button-text="No"
                            title="Are you sure to import and generate results? Existing data with the same ECN match will be updated"
                            @confirm="submitUpload">
                            <template #reference>
                              <el-button
                              style="margin-left: 10px;"
                              size="small"
                              type="success"
                              :loading="loading"
                            >Import and Generate Results</el-button>
                            </template>
                          </el-popconfirm>

                        </el-upload>
                      </el-form-item>
                  </el-form>
                </el-card>

              </el-tab-pane>
              <el-tab-pane label="Import Updated Tag Results">
                <p style="font-size: small">* Only for Updating Tag Results</p>
                <el-card>
                  <el-form ref="form" :model="results">
                      <el-alert style="margin-bottom:10px" :closable="false" title="Before uploading please rename the column headers to:" type="warning" 
                                description="`control_no`, `result`"/>
                      <el-alert style="margin-bottom:10px" :closable="false" title="Upload exam results (.csv, .xlsx) based on date and time of schedule respectively." type="warning" 
                                description=""/>
                      <el-alert style="margin-bottom:10px" :closable="false" title="Before Importing F2F results make sure to import first the applicant's data. Otherwise will result into error." type="error" 
                                description=""/>
                      <el-form-item>   

                        <el-upload
                        name="results_file"
                        action=""
                        :on-change="handleUploadChange"
                        :before-upload="handleBeforeUpload"
                        accept=".csv"
                        :file-list="fileList"
                        ref="upload"
                        :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">Choose file</el-button>
                        

                          <el-popconfirm
                            confirm-button-text="Continue"
                            cancel-button-text="No"
                            title="Are you sure to import and generate results? Existing data with the same ECN match will be updated"
                            @confirm="submitUpdate">
                            <template #reference>
                              <el-button
                              style="margin-left: 10px;"
                              size="small"
                              type="success"
                              :loading="loading"
                            >Import and Update Results</el-button>
                            </template>
                          </el-popconfirm>

                        </el-upload>
                      </el-form-item>
                  </el-form>
                </el-card>

              </el-tab-pane>
            </el-tabs>
          </template>

          
        </el-col>

        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-card>
                <el-divider content-position="left">Upload Logs</el-divider>
                <el-table :data="uploadlogs" stripe style="width: 100%, height: 5px">
                    <el-table-column prop="id" label="ID" ></el-table-column>
                    <el-table-column prop="file_name" label="File Name" ></el-table-column>
                    <el-table-column prop="batch_no" label="Batch No." ></el-table-column>
                    <el-table-column prop="import_type" label="Import Type" ></el-table-column>
                    <el-table-column prop="status" label="Status" ></el-table-column>
                </el-table>

                <!-- <table stripe style="width: 100%">
                    <el-table-column prop="id" label="ID" ></el-table-column>
                    <el-table-column prop="file_name" label="File Name" ></el-table-column>
                    <el-table-column prop="batch_no" label="Batch No." ></el-table-column>
                    </table> -->

                    <el-pagination
                    class="margin-top-20"
                    background
                    :page-size="15"
                    layout="prev, pager, next"
                    :total="meta.total"
                    @current-change="pageChanged"
                    :current-page="meta.page"
                    >
                    </el-pagination>
                   
            </el-card>
        </el-col>
    </el-row>

  </div>

</template>

<style>
  .right {
    text-align: right;
  }

  .add-btn {
    margin-bottom: 1rem;
  }
</style>

<script>

import { Applicant } from '@/api_services/applicant';
import { UploadLog } from "@/api_services/uploadlog";

export default {

  name: 'uploadresults',
  data() {
    return {
        uploadlogs: [],
        loading: false,
        results: {
            results_file: null,
        },
        meta: {
            page: 1,
            pages: 1,
            total: 211,
            per_page: 10,
        },
        showDialog: false,
        selected: null,
        import_type: null,
        file: {
            file_name: null,
            import_type: null,
            status: null,
        },
        fileList: [],
    }
  },
  created() {
    this.getLogs();
  },
  computed: {
    
  },
  methods: {
    pageChanged(val) {
      this.meta.page = val;
      this.getLogs();
    },
    getLogs() {
      // console.log(UploadLog);
      UploadLog.get({
        params: { term: '' },
      }).then((result) => {
        this.uploadlogs = result.data.data;
        this.meta = {
          page: result.data.meta.current_page,
          total: result.data.meta.total,
          per_page: result.data.meta.per_page,
          pages: result.data.meta.last_page,
        };
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        this.uploadlogs = [];
      });
    },
    submitUpload() {
      const formData = new FormData();
      formData.append('results_file', this.fileList[0].raw);
      this.$message.warning('Importing and calculating moodle results. please wait...');
      this.loading = true;
      Applicant.importres(
          formData, {'content-type': 'multipart/form-data'}
      )
      .then(result=> {
        console.log("success!");
        this.file_name = this.fileList[0].name;
        this.import_type = "IMPORT MOODLE RESULT";
        this.create();
        this.loading = false;
        this.$message({
            message: 'Imported and parsed successfully.',
            type: 'success',
            duration: 5000
        }); 
      }).catch(error => {
        console.error(error);
        this.loading = false;
        this.$message({
            message: 'Unable to upload the file.',
            type: 'error',
            duration: 5000
          }); 
      })
    },
    submitUpdate() {
      const formData = new FormData();
      formData.append('results_file', this.fileList[0].raw);
      this.$message.warning('Importing and Updating Final results. please wait...');
      this.loading = true;
      // console.log(file);
      Applicant.importresfinal(
          formData, {'content-type': 'multipart/form-data'}
      )
      .then(result=> {
        console.log("success! Updated.");
        this.file_name = this.fileList[0].name;
        this.import_type = "UPDATE TAGGING";
        this.create();
        this.loading = false;
        this.$message({
            message: 'Imported and Updated successfully.',
            type: 'success',
            duration: 5000
        }); 
      }).catch(error => {
        console.error(error);
        this.loading = false;
        this.$message({
            message: 'Unable to upload the file.',
            type: 'error',
            duration: 5000
          }); 
      })
    },
    create(){
      console.log("filename " + this.file);
      this.file.file_name = this.file_name;
      this.file.status = "success";
      this.file.import_type = this.import_type;
      
      UploadLog.create(this.file).then(response => {
        this.$emit("saved", response.data.data);
      }).catch((error) => {
        if (error.response.data.message) {
          this.$message({
            message: error.response.data.message,
            type: 'error',
            duration: 3000
          });
        }
      });
      
    },
    handleUploadChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    handleBeforeUpload(file) {
      const allowedCsvMime = [
        'text/csv',
        'text/plain',
        'application/csv',
        'text/comma-separated-values',
        'application/excel',
        'application/vnd.ms-excel',
        'application/vnd.msexcel',
        'text/anytext',
        'application/octet-stream',
        'application/txt',
      ];
      if (allowedCsvMime.includes(file.type)) {
        return true;
      } else {
        this.$message.error(
          'Invalid file type. (.cvs) files only'
        );
        this.fileList.pop(file);
      }
    },
  }
}
</script>
