<template>
    <div> 
        <el-row :gutter="20" style="padding:10px">
          

          <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
            
            <el-card class="box-card">
              <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                <el-form-item label="Date:">
                  <el-date-picker
                    v-model="searchForm.date"
                    placeholder="Date"
                    size="small"
                  ></el-date-picker>
                </el-form-item>

                <el-form-item>
                  <el-button
                    type="primary"
                    @click="onSearch"
                    size="small"
                    :loading="sched_loading"
                    icon="el-icon-search"
                    >Search</el-button
                  >
                </el-form-item>
                
              </el-form>
              <hr>
              <span style="color: #67C23A; font-size: 23px; font-weight: 600;">Schedules</span><br>
              <small style="color:#9090a5">*Select schedule to view / update proctors and generate exam roster</small>
              <el-table ref="singleTable" v-loading="sched_loading" :data="schedules" highlight-current-row class="highlightedTable"
              @current-change="handleCurrentChange" max-height="550"
              style="width: 100%; margin-top: 20px;" size="small">
                <el-table-column prop="date" label="Date" width="140"></el-table-column>
                <el-table-column label="Time">
                  <template slot-scope="scope" >
                    {{ scope.row.time_start + " - " + scope.row.time_end}}
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="time_end" label="End Time"></el-table-column> -->
                <el-table-column label="Proctors" align="center" width="100" >
                  <template slot-scope="scope" >
                    <b>{{ scope.row.assigned_proctor_ids !== null ? scope.row.assigned_proctor_ids.length : "0" }}</b>
                  </template>
                </el-table-column>

                <el-table-column label="Applicants in generated roster" align="center" width="130" >
                  <template slot-scope="scope" >
                    <b>{{ scope.row.sched_count_applicants }}</b>
                  </template>
                </el-table-column>

                <el-table-column prop="entries" label="Current scheduled applicants" align="center" width="130" ></el-table-column>
                <el-table-column
                  prop="tag"
                  label="Exam roster"
                  align="center">
                  <template slot-scope="scope">
                    <b v-if="scope.row.assigned_proctor_ids !== null" style="color:#67C23A">Yes</b>
                    <b v-if="scope.row.assigned_proctor_ids === null" style="color:#F56C6C">No</b>
                  </template>
                </el-table-column>
              </el-table>

            </el-card>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
                  
            <el-col>

              <el-card class="box-card" 
                v-if="hiddenCardTemp" >
                <div slot="header" class="clearfix">
                  <span> </span>
                  <br/>
                </div>
                <div style="margin: auto; text-align: center;">
                  <h3>Please select a schedule</h3>
                </div>
              </el-card>
              

              <el-card v-loading="loading" class="box-card" v-if="hiddenCard">
                <!-- <el-row> -->
                  <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
                      <span>Assigned proctors for <b><u>{{ selected_date_time }}</u></b></span>
                      <br/>
                  </el-col>
                  
                  <el-col v-if="can_generate" :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                    <el-button
                    style="width:100%;"
                      type="success"
                      @click="handleClickProctor"
                      size="small"
                      :loading="loading"
                      icon="el-icon-user">Add/Edit Proctors
                    </el-button>
                  </el-col>
                <!-- </el-row> -->
                
                <!-- <el-row> -->
                  <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
                    <span style="color: #ffffff">.</span>
                  </el-col>
                  <el-col v-if="examRoster" :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                    <el-button
                    style="margin-top:5px;margin-bottom:5px; width:100%;"
                      type="warning"
                      @click="exportRoster()"
                      size="small"
                      :loading="loading"
                      icon="el-icon-document">Export
                    </el-button>
                  </el-col>
                <!-- </el-row> -->
                
                
                <br>
                <hr style="margin-top:50px">
                <span style="color: #67C23A; font-size: 23px; font-weight: 600;">Proctors</span><br>
                <small style="color:#9090a5">*Select assigned proctor to view examinee's roster</small>
                <el-table :data="examRoster ? examRoster.filter(data => !search || data.proctor.name.toLowerCase().includes(search.toLowerCase())) : examRoster"
                v-loading="loading"
                element-loading-text="Loading..."
                element-loading-spinner="el-icon-loading" size="small" max-height="550"
                style="margin-top: 20px;" empty-text="No proctors and exam rosters available.">
                  <el-table-column prop="index" label="No." width="50"> </el-table-column>
                  <!-- <el-table-column prop="proctor.id" label="Proctor ID" width="100"></el-table-column>
                  <el-table-column prop="id" label="Roster ID" width="100"></el-table-column> -->
                  <el-table-column prop="proctor.name" label="Proctor"></el-table-column>
                  <!-- <el-table-column prop="zoom_link" label="zoom link" ></el-table-column> -->
                  <el-table-column label="Exam Roster" width="100">
                    <template slot-scope="scope">
                      <el-button style=" padding: 6px;" @click="handleViewRoster(scope.row)" type="info" size="small" icon="el-icon-view">View</el-button>
                    </template>
                  </el-table-column>
                  <el-table-column label="PDF file" width="100">
                    <template slot-scope="scope">
                      <el-link class="newtabBtn" style="
                  border: solid 1px #909399; 
                  padding: 2px 5px 2px 5px;
                  background-color: #909399;
                  color: #ffffff;
                  border-radius: 3px;
                  font-size: 12px;
                  " :href="scope.row.full_path" v-if="scope.row.url" target="_blank"><i class="el-icon-document"></i> Open</el-link>
                      <!-- <el-button style=" padding: 6px;" @click="handleViewRoster(scope.row)" type="info" size="small" icon="el-icon-view">View</el-button> -->
                    </template>
                  </el-table-column>
                </el-table>
              </el-card>

            </el-col>
      
          </el-col>

        </el-row>

        <el-row :gutter="20" style="padding:20px">
          
        </el-row>

        <el-dialog width="55%" :destroy-on-close="true" :visible.sync="showProctorDialog" v-if="showProctorDialog">
          <edit-proctors-component :selected_schedule="selected_schedule" @button-done="done"></edit-proctors-component>
        </el-dialog>

        <el-dialog title="EXAM ROSTER" width="95%" :destroy-on-close="true" :visible.sync="showExamRoster" v-if="showExamRoster">
          <view-roster-component :selected="selected" @update="updateDetails($event)"></view-roster-component>
        </el-dialog>
    </div>
</template>

<script>

import { Schedule } from "@/api_services/schedule";
import { Applicant } from "@/api_services/applicant";
import * as moment from 'moment';
import { User } from "@/api_services/user";
import { ExamRoster } from "@/api_services/examroster";
import EditProctorsComponent from './EditProctorsComponent.vue';
import ViewRosterComponent from './ViewRosterComponent.vue';


export default {
  components: {
    EditProctorsComponent, ViewRosterComponent
  },
  name: 'Dashboard',
  data() {
    return {
        schedules: [],
        examRoster: [],
        searchForm: {
          date: ""
        },
        loading: false,
        showDialog: false,
        selected: null,
        selected_schedule: null,
        selected_date_time: "",
        showProctorDialog: false,
        showExamRoster: false,
        hiddenCardTemp: true,
        hiddenCard: false,
        currentRow: null,
        form: {
          id: null
        },
        can_generate: true,
        sched_loading: false,
        search: ""
    }
  },
  created: function() {
    this.getSchedules();
  },
  methods: {
    getSchedules() {
      let date = moment().format('YYYY-MM-DD');
      // console.log(date)
      // if date = ;
      this.sched_loading = true
      Schedule.getAll({
        params: { from: date, filter: "all" },
      }).then((result) => {
          this.schedules = result.data.data;
          this.schedules.forEach(sched => {
            let totalExamReports = 0;
            let start = sched.time_start.split(":")[0]+":"+sched.time_start.split(":")[1];
            let end = sched.time_end.split(":")[0]+':'+sched.time_end.split(":")[1];
            sched.time_start = this.formatTime(sched.time_start)
            sched.time_end = this.formatTime(sched.time_end)
            sched.date = moment(sched.date).format('MMM D, YYYY')
            
            sched.roster.forEach(roster => {
                totalExamReports += roster.exam_reports.length;
            });
            sched.sched_count_applicants = totalExamReports;

          });
          // console.log(this.schedules)
          this.sched_loading = false
      }).catch((error) => {
          console.log(error);
      });
    },
    formatTime(time) {
      const [hours, minutes] = time.split(':');
      let hourNum = parseInt(hours, 10);
      const period = hourNum >= 12 ? 'PM' : 'AM';
      hourNum = hourNum % 12 || 12;
      const formattedTime = `${hourNum}:${minutes} ${period}`;

      return formattedTime;
    },
    updateDetails(event) {
      this.getProctors();
    },
    done(){
      this.showProctorDialog = false;
      this.showExamRoster = false;
      this.hiddenCardTemp= true;
      this.hiddenCard = false;
      this.getSchedules();
      
    },
    handleCurrentChange(val) {

      if(val){
        this.currentRow = val;
        // console.log(this.currentRow.assigned_proctor_ids)
        let day = moment(val.date).format('ddd');
        let start = val.time_start.split(":")[0]+":"+val.time_start.split(":")[1];
        let end = val.time_end.split(":")[0]+':'+val.time_end.split(":")[1];
        this.selected_date_time = moment(val.date).format('MMMM D, YYYY') + " (" + day + ") " + start + " - " + end;
        this.loading = true;
        this.hiddenCardTemp = false;
        this.hiddenCard = true;

        let date1 = moment();
        let date2 = moment(val.date);
        if(((date2).isBefore(date1))){
          this.can_generate = false;
        } else {
          this.can_generate = true;
        }
        // console.log(date1 + " " + date2 + this.can_generate);
        
      }

      if(this.currentRow.assigned_proctor_ids !== null){
        this.getProctors();
      } else {
        this.loading = false;
        this.examRoster = null;
        console.log("no proctors assigned yet");
      }
      
    },
    getProctors() {
      ExamRoster.getScheduledProctors({
        params: { schedid: this.currentRow.id },
      })
      .then((result) => {
        this.examRoster = result.data.data;
        // console.log(this.examRoster)
        this.examRoster.forEach((roster,index) => {
          roster.index = index + 1
        });
        // console.log(result.data.data)
        this.loading = false;
      }).catch((error) => {
        console.log(error);
        this.loading = false;
        this.examRoster = null;
      });
    },
    onSearch(){
      console.log(this.searchForm)
      this.getSchedules()
    },
    handleViewRoster(roster){
      this.selected = Object.assign({}, roster);
      // this.selected_schedule = Object.assign({}, this.currentRow);
      // console.log(this.selected);
      this.showExamRoster = true;
    },
    handleClickProctor(){
      this.selected_schedule = Object.assign({}, this.currentRow);
      this.showProctorDialog = true;
    },
    exportRoster(){
      Applicant.exportCsvList({
        sched_id: this.currentRow.id
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'applicants_'+ this.currentRow.date +'('+this.currentRow.time_start+'-'+this.currentRow.time_end+').csv');
        document.body.appendChild(link);
        link.click();
      });
    },

  }
}
</script>


