<template>
    <div>
      <span><b style="color: #ff5555;"> <i style="font-size: small;">Please select on the revert remark(s) then click save to send email notification to applicant</i></b></span>
      <br><br>
      <el-form ref="form"  :inline="false" class="demo-form-inline applicant-step-forms" :model="form" :rules="rules" @submit.native.prevent>

        <el-checkbox style="margin: 5px" v-model="form.selected_remarks" :label="revert_remark" v-for="revert_remark in $store.state.revert_remarks" :key="revert_remark">{{revert_remark}} 
        </el-checkbox> 

        <p v-if="prev_remarks" size="small" style="font-size: 11px; color:red;">Previous Remarks: {{ prev_remarks }}</p>
        <el-form-item label="Remarks" prop="remarks">
          <el-input type="textarea" :rows="3" v-model="form.remarks" placeholder="Remarks" size="small"></el-input>
        </el-form-item>
        <el-form-item style="text-align: right;">
          <el-button type="warning" icon="el-icon-d-arrow-left" @click="onSubmit">Save and Revert</el-button>
        </el-form-item>
      </el-form>

      <el-dialog
        width="30%"
        :visible.sync="innerVisible"
        :show-close="false"
        :close-on-click-modal="false"
        append-to-body>
        <div v-loading="loading" element-loading-text="Reverting..." style="margin: 0px auto; padding-bottom: 150px;"></div>
      </el-dialog>
    </div>
</template>

<script>

import * as moment from 'moment';
import { Applicant } from '@/api_services/applicant'

export default {
  name: 'ApplicantRemarks',
  props: ['applicant'],
  data() {
    return {
      innerVisible: false,
      submitting: false,
      loading: true,
      prev_remarks: [],
       form: { remarks: null, selected_remarks: [] },
        rules: {
          remarks: [
            { required: true, message: 'Remarks is required', trigger: 'blur' },
          ],
        }
    }
  },
  created: function() {
    this.form.remarks = this.applicant.remarks
    this.prev_remarks = this.applicant.selected_remarks
  },
  methods: {
    async onSubmit() {

      if (this.submitting) { return; }
      this.submitting = true;
      
       const valid = await this.$refs.form.validate();
      if (!valid) return;
        
        this.innerVisible = true
        Applicant.revertApplication(this.applicant.id, this.form)
        .then(result => {
          
          if (result) {
            this.$message({
              message: "Applicant has been reverted. An email notification has been sent as well",
              type: 'success',
              duration: 5000
            });
            this.$emit('update:applicant', result.data.data)
          }    
          this.$router.go(0);
        })
        .catch( error => {
          this.loading = false
          console.log(error)
          this.submitting = false
        })
    },
  }
}
</script>