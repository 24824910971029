<template>
  <div class="schedules">
    <div class="add-btn right" v-if="$store.state.savedUser.role === 'admin'">
      <el-button type="primary" size="medium" @click="addForm()">ADD Schedule</el-button>
    </div>

    <el-table :data="schedules" stripe style="width: 100%">
      <el-table-column prop="id" label="ID" width="70"></el-table-column>
      <el-table-column prop="date" label="Date"></el-table-column>
      <el-table-column prop="time_start" label="TimeStart"></el-table-column>
      <el-table-column prop="time_end" label="TimeEnd"></el-table-column>
      <el-table-column prop="slots" label="Slots"></el-table-column>
      <el-table-column prop="entries" label="Entries" width="200"></el-table-column>
      <el-table-column v-if="$store.state.savedUser.role === 'admin'"
        fixed="right"
        label="Operations"
        width="300">
        <template slot-scope="scope">
          <!-- <el-button type="primary" size="small" @click="exportApplicants($store.state.savedUser.id)" >Export Schedule</el-button> -->
          <el-button
            @click="showSched(scope.row)"
            type="text"
            size="small"
            style="margin-right: 5px"
            >View</el-button
          >
          <el-button @click="editSchedule(scope.row)" type="text" size="small">Edit</el-button>
          <el-button v-if="scope.row.entries === 0" @click="deleteSchedule(scope.row)" type="text" size="small">Delete</el-button>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="Results" width="100">unavailable</el-table-column>
    </el-table>

    <el-pagination
      class="margin-top-20"
      background
      layout="prev, pager, next"
      :total="meta.total"
      :page-size="15"
      @current-change="pageChanged"
      :current-page="meta.page"
    >
    </el-pagination>

    <el-dialog title="Schedule" width="75%" :visible.sync="showDialog" :destroy-on-close="true">
      <schedule-component :selected="selected" @saved="scheduleSaved($event)"></schedule-component>
    </el-dialog>
  </div>

</template>

<style>
  .right {
    text-align: right;
  }

  .add-btn {
    margin-bottom: 1rem;
  }
</style>

<script>
import { Schedule } from "@/api_services/schedule";
import ScheduleComponent from './ScheduleComponent.vue';
import * as moment from 'moment';

export default {
  components: { ScheduleComponent },
  name: 'Schedules',
  data() {
    return {
      schedules: [],
      meta: {
        page: 1,
        pages: 1,
        total: 211,
        per_page: 15,
      },
      loading: false,
      showDialog: false,
      selected: null
    }
  },
  created() {
    this.getSchedules();
  },
  methods: {
    editSchedule(schedule) {
      this.selected = Object.assign({}, schedule);
      this.showDialog = true;
    },
    deleteSchedule(schedule) {
      if (schedule.entries > 0) { return; }
      if (confirm('Are you sure you want to delete this schedule?')) {

        Schedule.delete(schedule.id, {}).then((response) => {
          this.getSchedules();
        }).catch((error) => {
          if (error.response.data.message) {
            this.$message({
              message: error.response.data.message,
              type: "error",
              duration: 3000,
            });
          }
        });
      }
    },
    addForm() {
      this.showDialog = true;
      this.selected = null;
    },
    scheduleSaved(data) {
      this.showDialog = false;
      this.schedules.push(data);
    },
     pageChanged(val) {
      this.meta.page = val;
      this.getSchedules();
    },
    getSchedules() {
      Schedule.get({
        params: { page: this.meta.page, term: '', per_page: this.meta.per_page },
      }).then((result) => {
        this.schedules = result.data.data;
        console.log(result.data)
        this.schedules.forEach(sched => {
          let start = sched.time_start.split(":")[0]+":"+sched.time_start.split(":")[1];
          let end = sched.time_end.split(":")[0]+':'+sched.time_end.split(":")[1];
          sched.time_start = this.formatTime(sched.time_start)
          sched.time_end = this.formatTime(sched.time_end)
          sched.date = moment(sched.date).format('MMMM D, YYYY')
        });

        this.meta = {
          page: result.data.meta.current_page,
          total: result.data.meta.total,
          per_page: result.data.meta.per_page,
          pages: result.data.meta.last_page,
        };
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        this.schedules = [];
      });
    },
    formatTime(time) {
      const [hours, minutes] = time.split(':');
      let hourNum = parseInt(hours, 10);
      const period = hourNum >= 12 ? 'PM' : 'AM';
      hourNum = hourNum % 12 || 12;
      const formattedTime = `${hourNum}:${minutes} ${period}`;

      return formattedTime;
    },
    showSched(item) {
      this.$router.push({ name: 'SelectedSchedule', params: { id: item.id } })
      // this.review = true;
      // this.selected = Object.assign({}, item);
    },
  }
}
</script>
