<template>
  <div class="schedules">
    <div class="add-btn right">
      <el-button type="primary" size="medium" @click="addForm()">Add User</el-button>
    </div>

    <el-table :data="users" stripe style="width: 100%">
      <el-table-column prop="id" label="ID" width="70"></el-table-column>
      <el-table-column prop="name" label="Name"></el-table-column>
      <el-table-column prop="email" label="Email"></el-table-column>
      <el-table-column prop="campus" label="Campus"></el-table-column>
      <el-table-column prop="role" label="Role"></el-table-column>
      <el-table-column label="Is Proctor">
        <template slot-scope="scope" >
          <b v-if="scope.row.is_proctor == true" style="color:#67C23A">YES</b>
          <b v-if="scope.row.is_proctor == false" style="color:#F56C6C">NO</b>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="Operations"
        width="120">
        <template slot-scope="scope">
          <el-button @click="editUser(scope.row)" type="text" size="small">Edit</el-button>
          <el-button v-if="scope.row.role != 'admin'" @click="deleteUser(scope.row)" type="text" size="small">Delete</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="margin-top-20"
      background
      layout="prev, pager, next"
      :total="meta.total"
      :page-size="15"
      @current-change="pageChanged"
      :current-page="meta.page"
    >
    </el-pagination>

    <el-dialog title="User" width="40%" :visible.sync="showDialog" :destroy-on-close="true" v-if="showDialog" class="responsive-dialog">
      <user-component :selected="selected" @saved="userSaved($event)"></user-component>
    </el-dialog>
  </div>

</template>

<style>
  .right {
    text-align: right;
  }

  .add-btn {
    margin-bottom: 1rem;
  }
</style>

<script>
import { User } from "@/api_services/user";
import UserComponent from './UserComponent.vue';

export default {
  components: { UserComponent },
  name: 'Users',
  data() {
    return {
      users: [],
      meta: {
        page: 1,
        pages: 1,
        total: 211,
        per_page: 10,
      },
      loading: false,
      showDialog: false,
      selected: null
    }
  },
  created() {
    this.getUsers();
  },
  methods: {
    pageChanged(val) {
      this.meta.page = val;
      this.getUsers();
    },
    editUser(user) {
      this.selected = Object.assign({}, user);
      this.showDialog = true;
    },
    deleteUser(user) {
      if (user.entries > 0) { return; }
      if (confirm('Are you sure you want to delete this user?')) {

        User.delete(user.id, {}).then((response) => {
          this.getUsers();
        }).catch((error) => {
          if (error.response.data.message) {
            this.$message({
              message: error.response.data.message,
              type: "error",
              duration: 3000,
            });
          }
        });
      }
    },
    addForm() {
      this.showDialog = true;
      this.selected = null;
    },
    userSaved(data) {
      this.showDialog = false;
      this.getUsers();
    },
    getUsers() {
      this.selected = null
      User.get({
        params: { page: this.meta.page, term: '' },
      }).then((result) => {
        this.users = result.data.data;
        this.meta = {
          page: result.data.meta.current_page,
          total: result.data.meta.total,
          per_page: result.data.meta.per_page,
          pages: result.data.meta.last_page,
        };
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        this.users = [];
      });
    }
  }
}
</script>
